//@flow

import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { loadCsvData, encryptData, decryptData } from "../../../utils/helpers";
import { DEFAULT_CONFIG } from "../../organism/MidiStage/constant";
import "./BankSelectDropdown.scss";

//
// Types
// ----------------

type Props = {
  ready: boolean,
  width: number,
  customvoices?: string,
  dropdownData: Array,
  onSubmit: Function,
  isDevelopment: boolean
}

type State = {
  isDevelopment: boolean,
  customvoices?: string,
  value?: object,
}

class CustomVoicesDropdown extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isDevelopment: this.props.isDevelopment,
      customvoices: this.props.customvoices,
      value: "",
    };
  }


  componentDidMount() {
    const { customvoices, dropdownData } = this.props;
    if(customvoices !== undefined) {
      const value = dropdownData.filter(d => d.label === customvoices)[0];
      this.setState({ value })
    }
  }


  componentWillReceiveProps(props) {
    let isReady = true;
    if(isReady && this.state.customvoices !== props.customvoices) {
      const value = props.dropdownData.filter(d => d.label === props.customvoices)[0];
      setTimeout(()=>{
        this.setState({ value })
        isReady = false;
      },0)
      
    }
  }


  handleChange = (e) => {
    this.setState({ value: e });
    this.props.onSubmit(e.label);
    return false;
  };

  filterInput = (selectedCat) => {
    
    const filteredGroupOptions = this.props.dropdownData.filter(d => d.label.toLowerCase().includes(selectedCat.toLowerCase()));

    return filteredGroupOptions
  }

  loadOptions = (input, callback) => {
    if (input.length > 2) {
      setTimeout(() => {
        callback(this.filterInput(input));
      }, 20);
    }
  };

  setResultsLimit = () => {
    const size = 20;
    let newDropdownData = this.props.dropdownData;
    newDropdownData = this.props.dropdownData.slice(0,size);
    return newDropdownData;
  };

  noOptionsMessage = () => {
    return null;
  };

  handleInputChange = (e, metaAction) => {
    const { action } = metaAction;
    if(action === 'menu-close') {
      // reset menu
      this.setState({ inputValue: null })

    }
  }

  formatGroupLabel = (data) => {
    return (
    <div className="group-heading__inner">
      <span>{data.label}</span>
      <span className="group-badge">{data.options.length}</span>
    </div>
  )};

  download(url, filename) {
    const a = document.createElement('a');
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  getMasterIndexCsv = async (url, validMasterHeaders, filename) => {
    
    let data = await loadCsvData(url, validMasterHeaders)
      .then(
        res => { return res }
      ).catch(err => console.log(err));
    const cipheredText = encryptData(data);
    const urlData = `data:application/octet-stream;charset=utf-8,${encodeURIComponent(
        JSON.stringify(
          cipheredText
        )
      )}`;
    this.download(urlData, filename);
  }

  render() {
    const { width } = this.props;
    
    const customStyles = {
      container: (base) => ({
        ...base,
        width: width - 44
      }),
      menuPortal: base => ({
        // this just updates the menuPortal style to trigger a rerender
        ...base,
     })
    };

    return (
      <div className="react-select__wrapper mb-2">
        <div className="label">custom voices</div>

        <AsyncSelect
          value={this.state.value}
          loadOptions={this.loadOptions}
          defaultOptions={this.setResultsLimit()}
          onChange={this.handleChange.bind(this)}
          onInputChange={this.handleInputChange.bind(this)}
          // formatGroupLabel={this.formatGroupLabel}
          placeholder={"Select a keyboard..."}
          classNamePrefix={"react-select"}
          styles={customStyles}
          filterOption={()=>({ 
            ignoreCase: false,
            ignoreAccents: false,
            trim: false,
            matchFromStart: false
          })}
          // menuIsOpen={true}
        />

        {/* {this.props.isDevelopment && 
        <ul className="d-flex flex-wrap" style={{listStyle: 'none',padding: 0}}>

          <li><button onClick={()=>{ 
            this.getMasterIndexCsv(process.env.REACT_APP_CUSTOM_VOICES_MASTER, DEFAULT_CONFIG.validMasterHeaders, process.env.REACT_APP_CUSTOM_VOICES_FILENAME)
            }} className="mybutton warning mr-2">{process.env.REACT_APP_CUSTOM_VOICES_FILENAME}</button></li>

          {this.state.value !== "" && <li><button onClick={()=>{ 
            this.getMasterIndexCsv(this.state.value.csvUrl, DEFAULT_CONFIG.validHeaders, this.state.value.filename + ".txt")
            }} className="mybutton warning mr-2">{this.state.value.filename}.txt</button></li>}

          {this.state.value !== "" && this.state.value.sysexUrl && <li><button onClick={()=>{ 
            this.getMasterIndexCsv(this.state.value.sysexUrl, DEFAULT_CONFIG.validSysexHeaders, this.state.value.filename + "_sysex.txt")
            }} className="mybutton warning">{this.state.value.filename}_sysex.txt</button></li>}

        </ul>
        } */}

      </div>
    );
  }
}

export default CustomVoicesDropdown;
