//@flow

import React, { Component } from "react";
import { makeid } from "./../../../utils/helpers";
import "./RadioSelectBox.scss";

//
// Types
// ----------------

type Props = {
  defaultOptions?: string | number,
  setCheckedOptions: Function,
  options: Array,
  optionNames?: Array,
  checkedOptions?: string | number
}

type State = {
  checkedOptions?: string | number
}

class RadioSelectBox extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      checkedOptions: this.props.checkedOptions,
    };
  }

  handleChange(e) {
    this.props.setCheckedOptions(+e.target.value);
    this.setState({ checkedOptions: +e.target.value });
  }

  render() {
    const {
      title,
      options,
      optionNames,
      isReadOnly,
    } = this.props;

    const checkedValue = (i) => {
      const { checkedOptions } = this.props;
      return checkedOptions === i;
    };

    const genId = makeid(4);

    const getOptionName = (i) => {
      if(optionNames) {
        return optionNames[i]
      } else {
        return options[i]
      }
    }

    return (
      <div className="radio-select-box">
        <form id={title} onChange={(e) => {
          this.handleChange(e);
        }} onSubmit={e => { e.preventDefault(); }}>
          {options.length > 0 &&
            options.map((d, i) => (
              <React.Fragment key={i}>
                {isReadOnly !== true && (
                  <>
                    <input
                      type="radio"
                      name={`${title}`}
                      id={`${title ? title : genId}_${i}`}
                      value={i}
                      checked={checkedValue(i)}
                      readOnly
                    />
                    <label
                      htmlFor={`${title ? title : genId}_${i}`}
                      title={getOptionName(i)}
                    >
                      {getOptionName(i)} 
                    </label>
                  </>
                )}
                {isReadOnly === true && (
                  <div className="label" title={getOptionName(i)}>
                    {getOptionName(i)} 
                  </div>
                )}
              </React.Fragment>
            ))}

        </form>
        {options.length <= 0 && (
          <div className="d-flex align-items-center justify-content-center"><div className="text-center">Connect a MIDI device</div></div>
        )}
      </div>
    );
  }
}

export default RadioSelectBox;
