//@flow

import React, { Component } from "react";
import "./SectionBox.scss";

//
// Types
// ----------------

type Props = {
  title?: string,
  customStyle?: string,
  children?: React.Node
}

type State = {}

class SectionBox extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      title,
      children,
      customStyle
    } = this.props;
  
    return (
      <div className={`section-box ${customStyle}`}>
        {title && <div className={`section-box__title`}>{title}</div>}
        <div className={`section-box__inner`}>{children}</div>
      </div>
    );
  }
}

export default SectionBox;
