// @flow

import React from 'react';

type Props = {
  all?: boolean,
  // utilities
  utilities?: boolean,
  loading?: boolean,
  search?: boolean,
  carat?: boolean,
  chevron?: boolean,
  tick?: boolean,
  validationAlert?: boolean,
  sliders?: Boolean,
  power?: Boolean,
  play?: Boolean,
  pause?: Boolean,
  stop?: Boolean,
  mute?: Boolean,
  unmute?: Boolean,
  // social
  social?: boolean,
  twitter?: boolean,
  email?: boolean,
  whatsapp?: boolean,
  facebook?: boolean,
  google?: boolean,
  instagram?: boolean,
};

const SvgIcons = ({
  all,
  // utilities
  utilities,
  loading,
  search,
  carat,
  chevron,
  tick,
  validationAlert,
  sliders,
  power,
  play,
  pause,
  stop,
  mute,
  unmute,
  // social
  social,
  twitter,
  email,
  whatsapp,
  facebook,
  google,
  instagram,
  // loaders
  loaders,
  loaderSMH,
  loaderTheAge,
  loaderWaToday,
  loaderBrisbaneTimes,
  loaderCT
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: 'absolute', width: 0, height: 0 }}
  >
    {/* Utilities */}
    {all || utilities || loading ? (
      <symbol viewBox="0 0 40 40" id="icon-loading">
        <circle
          className="loading__icon__circle"
          opacity="0"
          cx="15.8"
          cy="15"
          r="15"
        />
        <path
          d="M27.8084006,22.8842813 C29.5771785,20.6011257 30.6299412,17.7353365 
             30.6299412,14.6236613 C30.6299412,9.67647577 27.9688583,5.35081946 
             24,3"
          className="loading__icon__active-segment"
        />
      </symbol>
    ) : null}

    {all || utilities || search ? (
      <symbol viewBox="0 0 32 32" id="icon-search">
        <path
          d="M21.08 18.573l10.4 10.4a1.773 1.773 0 1 1-2.508 2.507l-10.4-10.4a11.62 11.62 0 0 1-6.902 2.26C5.225 23.34 0 18.115 0 11.67S5.225 0 11.67 0s11.67 5.225 11.67 11.67c0 2.583-.84 4.97-2.26 6.903zm-1.287-6.903a8.123 8.123 0 1 0-16.247 0 8.123 8.123 0 0 0 16.247 0z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </symbol>
    ) : null}

    {all || utilities || carat ? (
      <symbol viewBox="0 0 10 7" id="icon-carat">
        <path
          d="M1 1l4 3.74L9 1"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        />
      </symbol>
    ) : null}

    {all || utilities || chevron ? (
        <symbol viewBox="0 0 10 18" id="icon-chevron">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g transform="translate(-8.000000, -3.000000)" stroke="#0A1633" strokeWidth="1.3">
              <polyline transform="translate(13.000000, 12.000000) rotate(-270.000000) translate(-13.000000, -12.000000) " points="21 16 13 8 5 16"></polyline>
            </g>
          </g>
        </symbol>
      ) : null}

    {all || utilities || tick ? (
      <symbol viewBox="0 0 9 9" id="icon-tick">
        <path
          d="M2.978 9a.998.998 0 0 1-.706-.294L.292 6.728a1 1 0 0 1 1.415-1.414L2.853 6.46 7.188.416a1 1 0 0 1 1.625 1.166l-5.022 7A.994.994 0 0 1 2.978 9"
          fill="currentColor"
          fillRule="evenodd"
        />
      </symbol>
    ) : null}

    {all || utilities || validationAlert ? (
      <symbol viewBox="0 0 16 16" id="icon-validation-alert">
        <circle r="8" cx="8" cy="8" fill="currentColor" />
        <text
          textAnchor="middle"
          x="8"
          y="13"
          fill="#fff"
          style={{
            fontSize: '13px',
            fontWeight: 700,
            fontFamily: "'PT Sans', sans-serif"
          }}
        >
          !
        </text>
      </symbol>
    ) : null}

    {all || utilities || sliders ? (
      <symbol viewBox="0 0 40 40" id="icon-sliders">
        <path fill="currentColor" d="M40,7a1.71,1.71,0,0,1-2,1.31H13.58a.51.51,0,0,0-.6.43A6.66,6.66,0,0,1,.07,7.64,1.63,1.63,0,0,0,0,7.34V5.94a1.42,1.42,0,0,0,.07-.22A6.68,6.68,0,0,1,5.07.2c.29-.08.58-.13.87-.2H7.42a1.42,1.42,0,0,0,.22.07,6.63,6.63,0,0,1,5.22,4.15c.11.24.18.51.28.78h.47Q25.79,5,38,5a1.83,1.83,0,0,1,2,1.26ZM6.67,3.34A3.33,3.33,0,1,0,10,6.66,3.32,3.32,0,0,0,6.67,3.34Z"/><path fill="currentColor" d="M0,33a1.71,1.71,0,0,1,2-1.31H9.83a.43.43,0,0,0,.49-.35,6.64,6.64,0,0,1,12.68,0c.1.3.24.36.53.36h14.6a1.7,1.7,0,0,1,1.48.56A4.84,4.84,0,0,1,40,33v.78A1.82,1.82,0,0,1,38,35c-4.79,0-9.58,0-14.36,0h-.45l-.06.08-.14.41a6.6,6.6,0,0,1-4.49,4.25,11.06,11.06,0,0,1-1.14.26h-1.4a1.34,1.34,0,0,0-.18-.07A6.62,6.62,0,0,1,10.3,35.3l-.1-.3H9.75C7.18,35,4.6,35,2,35a1.82,1.82,0,0,1-2-1.26Zm16.65,3.69a3.33,3.33,0,1,0-3.3-3.38A3.33,3.33,0,0,0,16.65,36.66Z"/><path fill="currentColor" d="M0,19.61a1.78,1.78,0,0,1,2-1.28c5.9,0,11.81,0,17.72,0h.51a6.67,6.67,0,0,1,1.38-2.62A6.39,6.39,0,0,1,28,13.48a6.41,6.41,0,0,1,5,4.45.52.52,0,0,0,.6.42c1.48,0,3,0,4.44,0a1.76,1.76,0,0,1,2,1.28v.78a1.76,1.76,0,0,1-2,1.28c-1.5,0-3,0-4.52,0A.42.42,0,0,0,33,22,6.67,6.67,0,0,1,20.3,22c0-.1-.07-.2-.11-.32H1.89A1.74,1.74,0,0,1,.4,21.13a4.36,4.36,0,0,1-.4-.74ZM23.35,20a3.33,3.33,0,1,0,3.3-3.34A3.34,3.34,0,0,0,23.35,20Z"/>
      </symbol>
    ) : null }

    {all || utilities || power ? (
      <symbol viewBox="0 0 35 35" id="icon-power" style={{enableBackground:"new 0 0 35 35"}} xmlSpace="preserve">
        <g style={{display: "none"}} >
          <circle cx="17.47" cy="17.56" r="17.5" fill="currentColor"/>
        </g>
        <g>
          <path style={{fill:"none",stroke:"#FFFFFF",strokeWidth:3,strokeLinecap:"round",strokeMiterlimit:10}} d="M22.99,7.54c3.51,1.94,5.88,5.67,5.88,9.96c0,6.28-5.09,11.38-11.38,11.38S6.12,23.78,6.12,17.5 c0-4.22,2.3-7.91,5.72-9.87"/>
          <line style={{stroke:"#FFFFFF",strokeWidth:3,strokeLinecap:"round",strokeMiterlimit:10}} x1="17.5" y1="3.5" x2="17.5" y2="18.21"/>
        </g>
      </symbol>
    ) : null }

    {all || utilities || play ? (
      <symbol viewBox="0 0 35 35" id="icon-play" style={{enableBackground:"new 0 0 35 35"}} xmlSpace="preserve">
        <g style={{display: "none"}} >
          <circle cx="17.47" cy="17.56" r="17.5" fill="currentColor"/>
        </g>
        <g>
          <polygon points="26.67,17.29 19.88,21.98 13.09,26.67 13.09,17.29 13.09,7.92 19.88,12.61 	"/>
        </g>
      </symbol>
    ) : null }

    {all || utilities || pause ? (
      <symbol viewBox="0 0 35 35" id="icon-pause" style={{enableBackground:"new 0 0 35 35"}} xmlSpace="preserve">
        <g style={{display: "none"}} >
          <circle cx="17.47" cy="17.56" r="17.5" fill="currentColor"/>
        </g>
        <g>
          <g style={{display: "inline"}}>
            <line x1="13.37" y1="10.57" x2="13.37" y2="24.56"/>
            <path d="M13.37,25.92c-0.83,0-1.5-0.61-1.5-1.36V10.57c0-0.75,0.67-1.36,1.5-1.36s1.5,0.61,1.5,1.36v13.99 C14.87,25.31,14.2,25.92,13.37,25.92z"/>
          </g>
          <g style={{display: "inline"}}>
            <line x1="21.56" y1="10.57" x2="21.56" y2="24.56"/>
		        <path d="M21.56,25.92c-0.83,0-1.5-0.61-1.5-1.36V10.57c0-0.75,0.67-1.36,1.5-1.36s1.5,0.61,1.5,1.36v13.99 C23.06,25.31,22.39,25.92,21.56,25.92z"/>
          </g>
        </g>
      </symbol>
    ) : null }

    {all || utilities || stop ? (
      <symbol viewBox="0 0 35 35" id="icon-stop" style={{enableBackground:"new 0 0 35 35"}} xmlSpace="preserve">
        <g style={{display: "none"}} >
          <circle cx="17.47" cy="17.56" r="17.5" fill="currentColor"/>
        </g>
        <g>
          <rect x="9.92" y="9.79" width="15.38" height="15.38"/>
        </g>
      </symbol>
    ) : null }

    {all || utilities || mute ? (
      <symbol viewBox="0 0 35 35" id="icon-mute" style={{enableBackground:"new 0 0 35 35"}} xmlSpace="preserve">
        <g style={{display: "none"}} >
          <polygon points="15.19,12.32 9.91,12.32 9.91,22.84 15.19,22.84 24.31,29.33 24.31,5.83 	"/>
        </g>
        <g >
        <polygon points="8.19,12.32 2.91,12.32 2.91,22.84 8.19,22.84 17.31,29.33 17.31,5.83 	"/>
        <g>
          <path style={{fill:"none",stroke:"#FFF",strokeWidth:2, strokeLinejoin:"round",strokeMiterlimit:10}} d="M26.51,26.08c2.45-2.06,4.02-5.14,4.02-8.58s-1.57-6.53-4.02-8.58"/>
          <path style={{fill:"none",stroke:"#FFF",strokeWidth:2, strokeLinejoin:"round",strokeMiterlimit:10}} d="M23.55,22.87c1.6-1.26,2.64-3.18,2.64-5.37s-1.05-4.11-2.64-5.37"/>
          <path style={{fill:"none",stroke:"#FFF",strokeWidth:2, strokeLinejoin:"round",strokeMiterlimit:10}} d="M20.61,19.67c0.74-0.44,1.25-1.24,1.25-2.17c0-0.93-0.5-1.73-1.25-2.17"/>
        </g>
        </g>
      </symbol>
    ) : null }

    {all || utilities || unmute ? (
      <symbol viewBox="0 0 35 35" id="icon-unmute" style={{enableBackground:"new 0 0 35 35"}} xmlSpace="preserve">
        <g style={{display: "none"}} >
          <circle cx="17.47" cy="17.56" r="17.5" fill="currentColor"/>
        </g>
        <g>
          <polygon style={{fill:"none",stroke:"#FFF",strokeWidth:3, strokeLinejoin:"round",strokeMiterlimit:10}} points="8.43,12.99 3.83,12.99 3.83,22.17 8.43,22.17 16.39,27.83 16.39,7.33"/>
          <line style={{fill:"none",stroke:"#FFF",strokeWidth:2, strokeLinejoin:"round",strokeMiterlimit:10}} x1="21.39" y1="11.8" x2="32.43" y2="22.83"/>
          <line style={{fill:"none",stroke:"#FFF",strokeWidth:2, strokeLinejoin:"round",strokeMiterlimit:10}} x1="21.39" y1="22.83" x2="32.43" y2="11.8"/>
        </g>
      </symbol>
    ) : null }

    {/* Social */}

    {all || social || twitter ? (
      <symbol viewBox="0 0 32 32" id="icon-twitter">
        <path
          d="M32 6.371c-1.16.525-2.139.542-3.177.023 1.34-.811 1.4-1.38 1.884-2.916a12.91 12.91 0 0 1-4.115 1.596A6.431 6.431 0 0 0 21.864 3c-3.578 0-6.479 2.94-6.479 6.564 0 .515.058 1.016.167 1.496C10.167 10.787 5.394 8.173 2.2 4.2a6.612 6.612 0 0 0-.879 3.302 6.584 6.584 0 0 0 2.883 5.463 6.402 6.402 0 0 1-2.934-.82l-.001.082c0 3.18 2.234 5.833 5.198 6.437a6.43 6.43 0 0 1-2.927.112c.825 2.607 3.218 4.505 6.052 4.559A12.903 12.903 0 0 1 0 26.052 18.167 18.167 0 0 0 9.93 29c11.919 0 18.433-10.001 18.433-18.675 0-.286-.004-.566-.017-.85C29.61 8.551 31.132 7.69 32 6.371"
          fill="currentColor"
          fillRule="evenodd"
        />
      </symbol>
    ) : null}
    {all || social || email ? (
      <symbol viewBox="0 0 32 32" id="icon-email">
        <g fill="currentColor" fillRule="evenodd">
          <path d="M16.086 17.334L30.627 6.502A3.267 3.267 0 0 0 28.885 6h-25.6c-.621 0-1.194.177-1.685.47l14.486 10.864z" />
          <path d="M16.464 18.857a.805.805 0 0 1-.931-.002L.413 7.538C.158 7.98 0 8.478 0 9.018v13.847c0 1.695 1.435 3.077 3.2 3.077h25.6c1.765 0 3.2-1.382 3.2-3.077V9.018c0-.518-.147-1-.382-1.429L16.464 18.857z" />
        </g>
      </symbol>
    ) : null}
    {all || social || whatsapp ? (
      <symbol viewBox="0 0 32 32" id="icon-whatsapp">
        <path
          d="M22.189 24.818c-.856.078-.856.701-5.6-1.165-4.74-1.868-7.733-6.732-7.967-7.044-2.187-2.91-2.762-5.991-.273-8.715.79-.86 2.748-.824 3.19.235.387.934 1.32 3.229 1.438 3.462.117.235.194.507.037.817-.186.375-.38.814-1.166 1.596-.233.233-.475.484-.203.95.272.468 1.206 1.995 2.594 3.231 1.782 1.59 3.284 2.081 3.75 2.315.467.233.74.195 1.012-.117.271-.313 1.167-1.362 1.476-1.829.312-.467.624-.388 1.05-.233.428.155 2.72 1.283 3.188 1.518.466.23.778.35.895.544.701 1.177-.963 4.212-3.421 4.435M16 0C7.178 0 0 7.177 0 16c0 3.5 1.133 6.744 3.048 9.38L1.05 31.324l6.149-1.97A15.897 15.897 0 0 0 16 32c8.821 0 16-7.177 16-16 0-8.822-7.178-16-16-16"
          fill="currentColor"
          fillRule="evenodd"
        />
      </symbol>
    ) : null}
    {all || social || facebook ? (
      <symbol viewBox="0 0 32 32" id="icon-facebook">
        <path
          d="M18.7 17.4h5.1l.7-5.6h-5.8V8.1c0-1.6.5-2.7 2.8-2.7h3V.2c-.5 0-2.3-.2-4.4-.2-4.4 0-7.9 2.7-7.9 7.7v4.1H7.6v5.6h4.6V32h6.5V17.4z"
          fill="currentColor"
        />
      </symbol>
    ) : null}
    {all || social || google ? (
      <symbol viewBox="0 0 32 32" id="icon-google">
        <path
          d="M28.6 14.6v-3.4H26v3.4h-3.4v2.6H26v3.4h2.6v-3.4H32v-2.6zm-8-.6a8.2 8.2 0 0 1 .2 2.2c0 5.9-4 10.3-10.2 10.3A10.65 10.65 0 0 1 0 16 10.65 10.65 0 0 1 10.6 5.5a10.48 10.48 0 0 1 7.2 2.8l-3 3A6.38 6.38 0 0 0 4.2 16a6.24 6.24 0 0 0 6.4 6.3c3.2 0 5.4-1.8 5.8-4.3h-5.8v-3.9l10-.1z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </symbol>
    ) : null}
    {all || social || instagram ? (
      <symbol viewBox="0 0 32 32" id="icon-instagram">
        <g stroke="currentColor" fill="currentColor">
          <path
            d="M8.5 2.9h15c1.6 0 3 .7 3.9 1.7 1 .9 1.7 2.3 1.7 3.9v15c0 1.6-.7 3-1.7 3.9-.9 1-2.3 1.7-3.9 1.7h-15c-1.6 0-3-.7-3.9-1.7-1-.9-1.7-2.3-1.7-3.9v-15c0-1.6.7-3 1.7-3.9.9-1.1 2.3-1.7 3.9-1.7m15-1.9h-15C4.4 1 1 4.4 1 8.5v15C1 27.6 4.4 31 8.5 31h15c4.1 0 7.5-3.4 7.5-7.5v-15C31 4.4 27.6 1 23.5 1"
            strokeWidth="2"
          />
          <path
            d="M16 21c-1.4 0-2.6-.6-3.5-1.5-.9-.9-1.5-2.1-1.5-3.5s.6-2.6 1.5-3.5c.9-.9 2.2-1.5 3.5-1.5 1.4 0 2.6.6 3.5 1.5.9.9 1.5 2.2 1.5 3.5 0 1.4-.6 2.6-1.5 3.5-.9.9-2.1 1.5-3.5 1.5m0-12c-3.9 0-7 3.1-7 7s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7"
            strokeWidth="2"
          />
          <path d="M24 9.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5m0-4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5" />
        </g>
      </symbol>
    ) : null}
  </svg>
);

SvgIcons.defaultProps = {
  all: false,
  // utilities
  utilities: false,
  loading: false,
  search: false,
  carat: false,
  chevron: false,
  tick: false,
  validationAlert: false,
  sliders: false,
  power: false,
  play: false,
  pause: false,
  stop: false,
  mute: false,
  unmute: false,
  // social
  social: false,
  twitter: false,
  email: false,
  whatsapp: false,
  facebook: false,
  google: false,
  instagram: false,

};

export default SvgIcons;
