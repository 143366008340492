//@flow

import React, { Component } from "react";
import "./CookieConsent.scss";

//
// Types
// ----------------

type Props = {}

type State = {
  display: boolean
}

class CookieConsent extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      display: true
    };
  }

  handleClick=(e)=>{
    e.preventDefault();
    this.setState({ display: false })
  }

  render() {
    const { display } = this.state;
  
    return (
      <>
      {display && <div className="cookie-consent d-sm-flex align-items-center">
        <div className="mb-2 mb-sm-0">
          <h3>Cookie consent</h3>
          <p>By continuing to browse or by clicking 'Accept', you agree to the storing of cookies on your device to enhance your site experience and data collection mainly for development and analytical purposes.</p>
        </div>
        <div>
          <button className="mybutton warning" onClick={this.handleClick}>Accept and Close</button>
        </div>
      </div>}
      </>
    );
  }
}

export default CookieConsent;
