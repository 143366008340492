//@flow

import React, { Component } from "react";
import "./Loader.scss";

//
// Types
// ----------------

type Props = {
  loaded?: boolean
}

type State = {
  loaded?: boolean,
  loaderDisplayed: boolean
}

class Loader extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: null,
      loaderDisplayed: true
    };
  }

  componentDidMount() {
    const { loaded } = this.props;
    this.setState({ loaded })
  }

  componentDidUpdate() {
    const { loaded } = this.props;
    if(loaded !== this.state.loaded) {
      this.setState({ loaded },()=>{
        setTimeout(()=>{
          this.setState({ loaderDisplayed: false })
        },5000)
      })
    }
  }

  render() {
    const { loaded } = this.props;
    const { loaderDisplayed } = this.state;
    const loaderClass = loaded ? "loader hide" : "loader";
    return (
      <>
        {loaderDisplayed && <div className={loaderClass}>
          <div className="spinner">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default Loader;
