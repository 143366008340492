export const DEFAULT_CONFIG = {
  channelTotals: 16, // number
  drumChannels: [], // array
  defaultLayer: {
    channel: undefined,
    range: [0, 127],
    velocity: [0, 127],
    pitchbend: false,
    modulation: false,
    holdpedal: true,
    keyaftertouch: false,
    isEnabled: true,
    voice: undefined
  },
  defaultMidiRange: [0,127],
  defaultMasterTuningRange: [-64,63],
  defaultFineTuningRange: [-1,1],
  defaultOctaveRange: [-3,4],
  defaultTransposeRange: [-12,12],
  defaultPitchBendRange: [2,24],
  validHeaders: ['name', 'number', 'program', 'msb', 'lsb', 'label', 'value'],
  validSysexHeaders: ['group','name','component','sysexid','data'],
  validMasterHeaders: ['brand','model','sheetUrl','csvUrl','sysexUrl','filename','active','author','label','value']
};