// flow

import React, { Component } from "react";
import "./App.scss";
import SvgIcons from './../../molecules/SvgIcons';
import MidiStage from "../MidiStage/MidiStage";
import Loader from "../../atom/Loader/Loader";
import { csvFAQtoJSON, dynamicSort, decryptData } from "./../../../utils/helpers";
import { get } from "axios";

function getUrlArgumentValues() {
  var urlArguments = window.location.search.substring(1).split("amp;").join("").split('&');

  var argumentSet;
  var urlArgumentsAndValues = {};

  if (urlArguments.length > 0) {
    for (var i = 0; i < urlArguments.length; i++) {
      argumentSet = urlArguments[i].split('=');

      if (argumentSet.length >= 2) {
        urlArgumentsAndValues[argumentSet[0]] = decodeURIComponent(argumentSet[1]).split("and;").join("&");
      }
    }
  }
  return urlArgumentsAndValues;
}

const { configUrl, customVoices } = getUrlArgumentValues();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: undefined,
    };
  }
  // Custom voices template
  // https://docs.google.com/spreadsheets/d/1PewYG6nHsVPKXFpXlRm_KrfWt2BXk20ZggOe1xYnmmk/edit#gid=1359379569

  async componentDidMount() {
    
    const _configUrl = configUrl ? configUrl : "demo/dummy-data.json";
    const customVoicesBase = "customvoices/";
    const GMUrl = customVoicesBase + "gm.txt";

    const styleFileUrl = "demo/bolero-sff.json";

    try {
      const { data } = await get(_configUrl);

      const loadCustomVoicesOptions = await get(customVoicesBase + process.env.REACT_APP_CUSTOM_VOICES_FILENAME);

      const customVoicesOptionsData = decryptData(loadCustomVoicesOptions.data);
      const customVoicesOptions = customVoicesOptionsData.filter(d => d.brand !== 'Unknown' && (d.active.toLowerCase() === "true" || d.active === true))//.sort(dynamicSort("label"));

      const newData = this.processData(data, customVoicesOptions);

      const gm = await get(GMUrl);
      
      const faq =  await get("content/faq.csv");
      
      const isMobile = document.body.offsetWidth < 620;

      const style = await get(styleFileUrl);

      this.setState({
        config: {
          ...newData, 
          faq: csvFAQtoJSON(faq.data),
          gm: decryptData(gm.data),
          customVoicesOptions,
          isMobile,
          style
        }
      });
    } catch (err) {
      // check if the request was cancelled
      console.log(err.message +"\n" +_configUrl);
    }
  }

  processData = (appData, customVoicesOptions) => {
    const { data } = appData;
    const isEncrypted = typeof data === 'string';
    
    let newObj = {
      ...appData,
      data: isEncrypted ? decryptData(data) : data
    }
    if(customVoices) {

      const newCustomVoices = decodeURI(customVoices);

      const isCustomVoicesMatched = customVoicesOptions.map(d => d.label).indexOf(newCustomVoices) >= 0;

      if(isCustomVoicesMatched) {
        newObj.config.customvoices = newCustomVoices;
      } else {
        console.log("[ERR]",newCustomVoices,"custom voices not available.")
      }

    }
    if(process.env.NODE_ENV === 'development') console.log(newObj)
    return newObj
  }


  render() {
    const { config } = this.state;
    if (config) {
      return (
        <div className="App">
          <SvgIcons play pause stop power sliders mute unmute />
          <MidiStage {...config} />
        </div>
      );
    } else {
      return <div className="App"><Loader loaded={false} /></div>
    }
  }
}

export default App;
