//@flow

import React, { Component } from "react";
import { decryptData, saveDataToServer } from "../../../utils/helpers";
import "./UploadConfigFile.scss";

//
// Types
// ----------------

type Props = {
  setConfig: Function
}

type State = {
  data?: Array,
  percentages: number,
}

// https://stackoverflow.com/questions/55613438/reactwrite-to-json-file-or-export-download-no-server

// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop

class UploadConfigFile extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      percentages: 0,
    };
  }

  processData = (appData, filename) => {
    const { data } = appData;
    const isEncrypted = typeof data === 'string';
    let newObj = {
      ...appData,
      data: isEncrypted ? decryptData(data) : data
    }
    if(process.env.NODE_ENV === 'development') {
      console.log(newObj)
    } else {
      // send data to server here for research purposes
      saveDataToServer(newObj, "tmp", filename);
    }

    this.props.setConfig(newObj);
  }

  handleFileUpload = (e) => {
    if (e.target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.fileName = e.target.files[0].name;

      let output;
      fileReader.onload = (ev) => {
        output = JSON.parse(ev.target.result);
        const filename = ev.target.fileName;
        this.processData(output, filename);
      };
    }
  };

  onDropHandler = (ev) => {
    console.log("File(s) dropped", ev);

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          let file = ev.dataTransfer.items[i].getAsFile();

          console.log(
            "... file[" +
              i +
              "].name = " +
              file.name +
              " (" +
              file.type +
              "), size = " +
              file.size +
              " bytes, last modified = " +
              file.lastModifiedDate.toLocaleDateString()
          );
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        console.log(
          "... file[" + i + "].name = " + ev.dataTransfer.files[i].name
        );
      }
    }
  };

  dragOverHandler = (ev) => {
    console.log("File(s) in drop zone");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  };

  /* 
    // Send data to server 
    https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
  */
  uploadFile(file) {
    var url = "YOUR URL HERE";
    var xhr = new XMLHttpRequest();
    var formData = new FormData();
    xhr.open("POST", url, true);

    xhr.addEventListener("readystatechange", function() {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // Done. Inform the user
      } else if (xhr.readyState === 4 && xhr.status !== 200) {
        // Error. Inform the user
      }
    });

    formData.append("file", file);
    xhr.send(formData);
  }

  render() {
    return (
      <div
        className="upload-config-file"
        // onDrop={this.onDropHandler}
        // onDragOver={this.dragOverHandler}
      >
        <label className="mybutton" htmlFor="myinput">Load</label>
        <input id="myinput" type="file" onChange={this.handleFileUpload} accept="application/json" />
      </div>
    );
  }
}

export default UploadConfigFile;
