// @flow
import unique from "array-unique";
import { getCombinedGlobalAndLayerValue } from "./webmidi-helpers";

export default function generateChannelArr(data, selectedPreset) {

  let dataArr = [];
  let isDisabledArr = [];
  let isEnabledArr = [];
  let isEnabledWithVoiceArr = [];
  let pitchbendArr = [];
  let pitchbendRangeArr = [];
  let modulationArr = [];
  let holdpedalArr = [];
  let expressionArr = [];
  let keyaftertouchArr = [];
  let noteTranspositionArr = [];

  let reverbArr = [];
  let tremoloArr = [];
  let chorusArr = [];
  let panArr = [];

  const _selectedPreset = selectedPreset > data.length - 1 ? selectedPreset - 1 : selectedPreset

  const layersData = data[_selectedPreset].layers;
  const globalData = data[_selectedPreset].global;

  const globalCombinedTranspositionValue = getCombinedGlobalAndLayerValue(
    globalData && globalData.octave ? globalData.octave : undefined,
    globalData && globalData.transpose ? globalData.transpose : undefined
  );

  for (let i = 0; i < layersData.length; i++) {
    const {
      channel,
      range,
      velocity,
      percussion,
      pitchbend,
      pitchbendrange,
      modulation,
      holdpedal,
      expressioncourse,
      keyaftertouch,
      octave,
      transpose,
      isEnabled,
      pancoarse,
      reverblevel,
      tremololevel,
      choruslevel,
      voice
    } = layersData[i];

    dataArr.push(channel);
    if (isEnabled) isEnabledArr.push(channel);
    if (isEnabled && voice) {
      isEnabledWithVoiceArr.push(channel);
      if (!reverblevel) reverbArr.push(channel);
      if (!tremololevel) tremoloArr.push(channel);
      if (!choruslevel) chorusArr.push(channel);
      if (!pancoarse) panArr.push(channel);
      if (!pitchbendrange) pitchbendRangeArr.push(channel);
    }
    if (!isEnabled) isDisabledArr.push(channel);
    if (pitchbend) pitchbendArr.push(channel);
    if (modulation) modulationArr.push(channel);
    if (holdpedal) holdpedalArr.push(channel);
    if (expressioncourse) expressionArr.push(channel);
    if (keyaftertouch) keyaftertouchArr.push(channel);

    if (isEnabled && !percussion) {
      noteTranspositionArr.push(
        getCombinedGlobalAndLayerValue(
          octave ? octave[0] : undefined,
          transpose ? transpose[0] : undefined
        ) + globalCombinedTranspositionValue
      );
    }

  }

  // Generate note transposition Arr object
  let noteTranspositionArrObj = {}

  /*
  Object.keys(arr) = [0,1,13]
    {
      0: [2],
      1: [1], // interval combined octave & transpose, channel
      13: [3]
    }
  */

  noteTranspositionArr = unique(noteTranspositionArr);

  noteTranspositionArr.forEach(interval => {
    let arr = [];
    layersData.forEach(d => {
      const { octave, transpose } = d;
      const channelCombinedValue = getCombinedGlobalAndLayerValue(octave ? octave[0] : undefined, transpose ? transpose[0] : undefined);
      if (channelCombinedValue + globalCombinedTranspositionValue === interval
        && d.isEnabled) arr.push(d.channel)
    })
    noteTranspositionArrObj[interval] = arr
  });

  return {
    dataArr,
    isDisabledArr,
    isEnabledArr,
    isEnabledWithVoiceArr,
    pitchbendArr,
    pitchbendRangeArr,
    modulationArr,
    holdpedalArr,
    expressionArr,
    keyaftertouchArr,
    chorusArr,
    reverbArr,
    noteTranspositionArr,
    noteTranspositionArrObj
  }
}