import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';
import { noteNumberToNoteName } from '../../../utils/helpers';
import './TwoThumbsRangeSlider.scss';

//
// Types
// ----------------

type Props = {
  onFinalChange: Function,
  onReset?: Function,
  onSetValue?: Function,
  step?: Number,
  defaultValues?: Array | number,
  values: Array | number,
  min: number,
  max: number,
  label: String,
  displayNoteNames?: boolean
}

type State = {
  trackingValues: Array,
  isDragging: boolean,
  displayNoteNames: boolean
}

export default class TwoThumbsRangeSlider extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.myTwoThumbsRangeSlider = React.createRef();
    this.state = {
      trackingValues: this.props.values,
      isDragging: false,
      displayNoteNames: false
    };
  }

  componentDidMount() {
    this.setState({ trackingValues: this.props.values })
  }

  UNSAFE_componentWillUpdate(props) {
    if(!this.state.isDragging) {
      if(this.state.trackingValues[0] !== props.values[0] || this.state.trackingValues[1] !== props.values[1]) {
        setTimeout(()=>{
          this.setState({ trackingValues: props.values })
        },0)
      }
    }
  }

  handleStart=(e)=>{
    this.setState({isDragging: true})
  }

  handleCompleted=(e)=>{
    this.setState({isDragging: false})
  }

  resetValue = (e) => {
    const { min, max, defaultValues } = this.props;

    const isDefaultValues = defaultValues !== undefined && defaultValues.length > 0;
    const _defaultValues = isDefaultValues ? defaultValues : [min, max]

    this.setState({ trackingValues: _defaultValues })
    this.props.onReset(_defaultValues);
  }
  

  render() {
    const { values, step, onReset, defaultValues, globalValues, layerValues } = this.props;
    const isResettable = onReset !== undefined;
    const { trackingValues, isDragging } = this.state;
    const { min, max, label, displayNoteNames } = this.props;
    const _defaultValues = defaultValues ? defaultValues : [min,max]
    const isValuesDefault = values[0] === _defaultValues[0] && values[1] === _defaultValues[1];
    const hasGlobalValues = globalValues;
    const isGlobalValuesUsed = layerValues === undefined && globalValues;
    const rtl = false;
    return (
      <div className="two-thumbs-range-slider">
        <Range
          // disabled={true}
          // draggableTrack
          step={step ? step : 1}
          min={min}
          max={max}
          values={trackingValues}
          onChange={(e) => {
            this.setState({ trackingValues: e });
          }}
          onFinalChange={(e)=>{
            this.props.onFinalChange(e)
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={this.handleStart}
              onTouchStart={this.handleStart}
              onMouseUp={this.handleCompleted}
              onTouchEnd={this.handleCompleted}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
                margin: '0 0 0',
                padding: '0 12px'
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '3px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values: isDragging ? trackingValues : values,
                    colors: ['#555', '#548BF4', '#555'],
                    min,
                    max,
                    rtl
                  }),
                  alignSelf: 'center'
                }}
              >
                {children}
                {label && 
                  <div 
                    className={`label ${isResettable ? "is--resettable":""} ${isValuesDefault ? "" : "is--not-default"}`} 
                    >
                    {hasGlobalValues && <span className={`has--global-value ${isGlobalValuesUsed ? "global-value-used":""}`} onClick={this.syncToGlobal}>+</span>}
                    <span onClick={(e)=> isResettable ? this.resetValue(e) : {} }
                    title={isResettable ? `reset to ${_defaultValues}`:""}>{label}</span>
                  </div>
                }
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '24px',
                width: '24px',
                borderRadius: '4px',
                backgroundColor: '#222',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // boxShadow: '0px 2px 6px #AAA',
                border: '1px solid #555',
                outline: 'none',
              }}
            >
              <div 
                style={{
                  height: '10px',
                  width: '3px',
                  background: isDragged ? '#548BF4' : '#AAA'
                }}>
              </div>
              <div
                 className="thumb-figure"
                 style={{
                   opacity: isDragged ? 1 : 0
                }}>{displayNoteNames ? noteNumberToNoteName(trackingValues[props.key]) : trackingValues[props.key]}
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}