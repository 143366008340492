// @flow
import { get } from "axios";
import unique from "array-unique";
import { decryptData, hexToDec, slugify, capitalise } from "../../../../utils/helpers";
import { sendSysexMessage } from "./webmidi-helpers";

export async function generateSysexControl(self,newCustomvoices) {

  const { data, selectedPreset, customvoices, customVoicesOptions, isDevelopment } = self.state;

  const _customvoices = newCustomvoices !== undefined ? newCustomvoices : customvoices;

  if (_customvoices && self) {

    const selectedCustomVoicesOption = customVoicesOptions.filter(d => d.label === customvoices)[0];

    if (selectedCustomVoicesOption.sysexUrl !== "") {

      const selectedObj = customVoicesOptions.filter(d => d.label === _customvoices)[0];

      const sysexControlArr = await get("customvoices/" + selectedObj.filename + "_sysex.txt").then(
        res => {

          const _rawSysexLayoutData = decryptData(res.data);
          let arr = [];
          const groups = unique(_rawSysexLayoutData.map(d => d.group));
          groups.forEach(g => {
            let optionsArr = [];
            let component = undefined;
            _rawSysexLayoutData.forEach(d => {
              if (g === d.group) {
                optionsArr.push({
                  manufacturer: hexToDec(d.sysexid),
                  name: d.name,
                  data: d.data
                })
                if (!component) component = d.component;
              }
            })
            let newObj = {};
            newObj.label = g;
            newObj.value = "sysex" + capitalise(slugify(g).split("-").join(""));
            newObj.component = component;
            if (optionsArr.length > 0) newObj.options = optionsArr;
            arr.push(newObj)
          })
          return arr;
        }
      );

      if (isDevelopment) console.log("sysexControlArr", sysexControlArr)
      self.setState({ sysexControlArr, haveSysexControl: true }, () => {

        // check is global data exist? 
        // if so, sendSysex for each component type
        const globalData = data[selectedPreset].global;
        if (globalData) {
          const sysexHeaders = Object.keys(globalData).filter(d => d.indexOf("sysex") >= 0);
          if (sysexHeaders.length > 0) {
            sysexHeaders.forEach((key, i) => {
              sysexControlArr.forEach(group => {
                if (group.value === key) {
                  const selectedGroup = group;

                  // fancyRadio
                  if (group.component === "fancyRadio") {
                    const selectedSysexOptionObj = selectedGroup.options.filter(o => o.name === globalData[key])[0]
                    sendSysexMessage(selectedSysexOptionObj, self.state.devicesOutputsActive, self.setMidiEvent, self.state.channelArr.isEnabledWithVoiceArr)
                  }

                  // different component type here ...


                }
              })
            });
          }
        } // globalData


      });

    } else {
      // no sysex
      self.setState({ sysexControlArr: [], haveSysexControl: false })
    }
  }
}