// @flow

import React, { useState, useEffect } from 'react';
import './Noticebox.scss';

type Props = {
    title: string, 
    date: string, 
    defaultExpanded: boolean, 
    children?: any
};

const Noticebox = ({ title, date, defaultExpanded, children }: Props) => {

    // Declare a new state variable, which we'll call "count"
    let [expanded, setExpanded] = useState(false);
    let [init, setInit] = useState(true);
    let customClass = expanded ? "is--expanded" : "";
    if(defaultExpanded && init) {
        setExpanded(true);
        setInit(false);
        setTimeout(()=>{
            setExpanded(false);
        },5000)
    };
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Code here
        if(init) setInit(false);
    },[init,expanded]);

    return (
        <div className={`notice-box ${customClass}`}>
            <div className="title" onClick={() => setExpanded(!expanded)} >
                <h3>
                    {title}
                    {/* {date && <span className="date"> ({date})</span>} */}
                </h3>
                
                <div className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 7" height="12px" width="12px" stroke="currentColor">
                        <path
                        d="M1 1l4 3.74L9 1"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        fillRule="evenodd"
                        />
                    </svg>
                </div>
                
            </div>
            <div className="body">
                {children}
            </div>
        </div>
    )
};

export default Noticebox;
