// @flow
import { get } from "axios";
import { decryptData } from "../../../../utils/helpers";
import { generateSysexControl } from "./generate-sysex-panel";

export async function generateDropdownData(self, newCustomvoices) {

  self.setState({ isDropdownDataReady: false });

  const { gm, customvoices, customVoicesOptions, groupedOptions } = self.state;

  const _customvoices = newCustomvoices !== undefined ? newCustomvoices : customvoices;

  let newGroupedOptions = groupedOptions

  if (_customvoices) {

    const selectedObj = customVoicesOptions.filter(d => d.label === _customvoices)[0];

    newGroupedOptions = await get("customvoices/" + selectedObj.filename + ".txt").then(
      res => {
        return [
          { label: "Custom voices", options: decryptData(res.data) },
          { label: "Default", options: gm }
        ]
      }
    );

    self.setState({ groupedOptions: newGroupedOptions, isDropdownDataReady: true }, () => {
      generateSysexControl(self);
    });

  } else {
    const defaultGroupOptions = [{ label: "Default", options: gm }]
    self.setState({ groupedOptions: defaultGroupOptions, isDropdownDataReady: true })
  }

  return false
}