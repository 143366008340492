// @flow
import { sendControlChange, sendPitchBendRangeMessage, sendMasterTuningMessage } from "./webmidi-helpers";
import generateChannelArr from "./generate-channel-arr";

export function updateSingleLayerData(values, i, type, self) {
  const { data, selectedPreset, devicesOutputsActive, isDevelopment } = self.state;

  let newLayerData = data[selectedPreset].layers[i];
  newLayerData[`${type}`] = values;

  // generic function storing data
  let newData = data;
  newData[selectedPreset].layers[i] = newLayerData;
  self.setState({
    data: newData,
    channelArr: generateChannelArr(data, selectedPreset)
  });

  // trigger MIDI message
  const { channel } = newLayerData;
  if (isDevelopment) console.log(values[0], channel, type)

  if (type === "expressioncoarse" || type === "pancoarse" || type === "reverblevel" || type === "tremololevel" || type === "choruslevel") {
    sendControlChange(values[0], channel, type, devicesOutputsActive, self.setMidiEvent);
  }

  if (type === "pitchbendrange") {
    sendPitchBendRangeMessage(values[0], devicesOutputsActive, self.setMidiEvent, [channel]);
  }

  if (type === "tuning") {
    sendMasterTuningMessage(values[0], devicesOutputsActive, self.setMidiEvent, [channel]);
  }
}

export function deleteSingleLayerData(i, type, self) {
  const { data, selectedPreset, devicesOutputsActive, isDevelopment } = self.state;
  let newLayerData = data[selectedPreset].layers[i];
  delete newLayerData[`${type}`];

  let newData = data;
  newData[selectedPreset].layers[i] = newLayerData;

  self.setState({
    data: newData
  });

  // trigger MIDI message
  const { channel } = newLayerData;
  const globalData = data[selectedPreset].global;
  // check global data exist, apply values, otherwise [0]
  const values = globalData && globalData[`${type}`] !== undefined ? globalData[`${type}`] : [0];
  if (isDevelopment) console.log(values[0], channel, type);

  if (type === "reverblevel" || type === "pancoarse" || type === "tremololevel" || type === "choruslevel") {
    sendControlChange(values[0], channel, type, devicesOutputsActive, self.setMidiEvent);
  }

  if (type === "pitchbendrange") {
    sendPitchBendRangeMessage(values[0], devicesOutputsActive, self.setMidiEvent, [channel]);
  }

}