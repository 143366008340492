import WebMidi from "webmidi";
import { convertRange, hexToDec, sendEvent } from "../../../../utils/helpers";
import { DEFAULT_CONFIG } from "../constant";
import unique from "array-unique";

/*
Global variables
*/

const DEVICES_INPUTS = WebMidi.inputs;
const DEVICES_OUTPUTS = WebMidi.outputs;

let lastNote = undefined;
let newNote = undefined;

let SELF;

let globalCombinedTranspositionValue = 0;

/*
Helpers
*/

/**
 * Convert a midi note into a pitch
 */
 export function midiToPitch(midi) {
	const octave = Math.floor(midi / 12) - 1;
	return midiToPitchClass(midi) + octave.toString();
}

/**
 * Convert a midi note to a pitch class (just the pitch no octave)
 */
function midiToPitchClass(midi) {
	const scaleIndexToNote = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
	const note = midi % 12;
	return scaleIndexToNote[note];
}

const setMidiEvent = (e) => {
  SELF.setState({ midiEvent: e })
}

const SimplifyWebMidiInputsOutputsArray = (array) => {
  let newArray = [];
  for (let i = 0; i < array.length; i++) {
    const { manufacturer, name, id, type } = array[i];
    newArray.push({ manufacturer, name, id, type });
  }
  return newArray;
};


/*
// WebMidi
//  https://webmidijs.org/docs/latest/classes/WebMidi.html
*/


export function initialiseWebMidi(self) {

  SELF = self;

  WebMidi.enable(function (err) {
    let ready = false;
    if (err) {
      console.log(err);
      self.setState({ ready });
      return false
    } else {
      ready = true;
      self.setState({ ready });
    }

    if (ready) {
      /* Devices */
      WebMidi.removeListener();
      WebMidi.addListener("connected", function (e) {

        self.setState({
          devicesInputs: SimplifyWebMidiInputsOutputsArray(DEVICES_INPUTS),
          devicesInputsActive: self.state.devicesInputsActive,
          devicesOutputs: SimplifyWebMidiInputsOutputsArray(DEVICES_OUTPUTS),
          devicesOutputsActive: self.state.devicesOutputsActive,
        });

        initialiseDevicesInputsOutputs(self, self.state.devicesInputsActive, "input");

      });
      WebMidi.addListener("disconnected", function (e) {

        self.setState({
          devicesInputs: SimplifyWebMidiInputsOutputsArray(DEVICES_INPUTS),
          devicesInputsActive: 0,
          devicesOutputs: SimplifyWebMidiInputsOutputsArray(DEVICES_OUTPUTS),
          devicesOutputsActive: 0,
        });

        initialiseDevicesInputsOutputs(self, self.state.devicesInputsActive, "output");

      });
    }
  }, true); // WebMidi end
}

/*
Midi modifier
*/

//{channel:1, range: [7,97], velocity:[0,127], pitchbend: true, controlchange: true},
const processMIDI = (e, output, self) => {

  const { data, selectedPreset, isDevelopment, devicesInputsActive, devicesOutputsActive, channelArr, velocityCurveDeviation, selectedChannel } = self.state;
  const isOutputActive = devicesOutputsActive >= 0;
  const isInputActive = devicesInputsActive >= 0;

  /* e */
  const type = e.type;
  const isEventNoteOn = type === "noteon";
  const isEventNoteOff = type === "noteoff";
  const isEventPitchbend = type === "pitchbend";
  const isEventControlChange = type === "controlchange";
  const isEventProgramChange = type === "programchange";
  const isEventKeyAfterTouch = type === "keyaftertouch";

  const note = isEventNoteOn || isEventNoteOff ? e.note.number : undefined;

  lastNote = note;
  newNote = note;

  const rawVelocity =
    isEventNoteOn || isEventNoteOff ? ConvertMidiValue(e.rawVelocity, velocityCurveDeviation) : 0;

  let newRawVelocity = rawVelocity;
  let isCustomVelocityRange = false;

  if (isOutputActive && isInputActive) {

    let noteOnReadyChannelArr = [];

    const activeData = data[selectedPreset];
    const layersData = activeData.layers;
    const globalData = activeData.global;

    const isSongMode = activeData.type !== undefined && activeData.type === 'song' ? true : false;

    globalCombinedTranspositionValue = getCombinedGlobalAndLayerValue(globalData && globalData.octave !== undefined ? globalData.octave : undefined, globalData && globalData.transpose !== undefined ? globalData.transpose[0] : undefined);

    lastNote = newNote;
    newNote = note + globalCombinedTranspositionValue;

    // isWithinConfigRange
    const isNewNoteWithinDefaultRange = newNote >= DEFAULT_CONFIG.defaultMidiRange[0] && newNote <= DEFAULT_CONFIG.defaultMidiRange[1];

    

    layersData.forEach(({ isEnabled, channel, range, velocity, voice }) => {
      
      // haveVoice
      const haveVoice = voice !== undefined && voice !== "";

      // isWithinRange
      const isWithinRange = note >= range[0] && note <= range[1];

      // rawVelocity
      const isWithinVelocity = rawVelocity >= velocity[0] && rawVelocity <= velocity[1];

      isCustomVelocityRange = velocity[0] !== DEFAULT_CONFIG.defaultMidiRange[0] && velocity[1] !== DEFAULT_CONFIG.defaultMidiRange[1];

      if(isCustomVelocityRange) {
        newRawVelocity = Math.floor(convertRange(rawVelocity,[velocity[0],velocity[1]],[DEFAULT_CONFIG.defaultMidiRange[0],DEFAULT_CONFIG.defaultMidiRange[1]]));
      }

      if (isEnabled && isWithinRange && isWithinVelocity && haveVoice && ( isEventNoteOn || isEventNoteOff )) noteOnReadyChannelArr.push(channel);

    });

    const isChannelTranspositionExist = channelArr.noteTranspositionArr.length > 1 ||
    (channelArr.noteTranspositionArr.length === 1 && channelArr.noteTranspositionArr[0] !== 0);
    
    if(!isChannelTranspositionExist) {

      // fire each output event once here
      if (noteOnReadyChannelArr.length > 0 && isEventNoteOn && isNewNoteWithinDefaultRange) {

        output.playNote(
          newNote, // note
          isSongMode ? [selectedChannel] : noteOnReadyChannelArr, 
          { velocity: isCustomVelocityRange ? newRawVelocity : rawVelocity, rawVelocity: true }
        );

        if (isDevelopment) {
          console.log(
            type,
            "channel " + (isSongMode ? [selectedChannel] :  noteOnReadyChannelArr),
            " note",
            newNote,
            "rawVelocity",
            rawVelocity
          );
        }
        self.setState({ midiEvent: isSongMode ? [selectedChannel] : noteOnReadyChannelArr }); // send back to parent component
      }

    } else {

      // isChannelTranspositionExist
      
      channelArr.noteTranspositionArr.forEach( transpose => {
        
        lastNote = newNote;
        newNote = note + transpose; // reset note

        const transposedChannelArr = channelArr.noteTranspositionArrObj[transpose];
        
        let filteredTransposedChannelArr = [];
        noteOnReadyChannelArr.forEach(c => {
          transposedChannelArr.forEach(t => {
            if(c === t) filteredTransposedChannelArr.push(c) 
          })
        });

        // fire each output event once here
        if (filteredTransposedChannelArr.length > 0 && isEventNoteOn && isNewNoteWithinDefaultRange) {

          output.playNote(
            newNote, // note
            isSongMode ? [selectedChannel] : filteredTransposedChannelArr, 
            { velocity: rawVelocity, rawVelocity: true }
          );

          if (isDevelopment) {
            console.log(
              type,
              "channel " + isSongMode ? [selectedChannel] : filteredTransposedChannelArr,
              " note",
              newNote,
              "rawVelocity",
              rawVelocity
            );
          }
          self.setState({ midiEvent: isSongMode ? [selectedChannel] : filteredTransposedChannelArr }); // send back to parent component
        }


      });


    } // end isChannelTranspositionExist


    if (channelArr.dataArr.length > 0 && isEventNoteOff && isNewNoteWithinDefaultRange) {
      const noteOffArr = unique([lastNote,newNote]);
      output.stopNote(
        noteOffArr, // note
        isSongMode ? [selectedChannel] : channelArr.dataArr, 
        { velocity: 0, rawVelocity: true }
      ); // note off velocity 0
      if (isDevelopment)
        console.log(
          type,
          "channel " + isSongMode ? [selectedChannel] : channelArr.dataArr,


          "note",
          note,"lastNote",lastNote,"newNote",newNote, // note
          "rawVelocity",
          rawVelocity
        );
      // self.setState({ midiEvent: dataArr }); // send back to parent component  
    }

    if (channelArr.keyaftertouchArr.length > 0 && isEventKeyAfterTouch) {
      const { value } = e;
      output.sendKeyAftertouch(
        newNote, // note
        isSongMode ? [selectedChannel] : channelArr.keyaftertouchArr, 
        { pressure: value }
      );
      if (isDevelopment)
      console.log(
        type,
        "channel " + isSongMode ? [selectedChannel] : channelArr.keyaftertouchArr,
        " note",
        newNote, // note
        "pressure",
        value
      );

      self.setState({ midiEvent: isSongMode ? [selectedChannel] : channelArr.keyaftertouchArr }); // send back to parent component  
    }

    if (channelArr.pitchbendArr.length > 0 && isEventPitchbend) {
      const { value } = e;
      output.sendPitchBend(value, isSongMode ? [selectedChannel] : channelArr.pitchbendArr); // only channel by default 1

      if (isDevelopment)
        console.log(type, "channel ", isSongMode ? [selectedChannel] : channelArr.pitchbendArr, " value", value);

      self.setState({ midiEvent: isSongMode ? [selectedChannel] : channelArr.pitchbendArr }); // send back to parent component  

    }

    if (isEventControlChange) {
      const { controller, value } = e;
      const { number, name } = controller;

      // https://webmidijs.org/docs/latest/classes/Output.html#method_sendControlChange
      const allowedCommonCCnumber = [
        // 0, // bankselectcoarse (#0)
        1, // modulationwheelcoarse (#1)
        // 6, // dataentrycoarse (#6)
        7, // volumecoarse (#7)
        // 10, // pancoarse (#10)
        11, // expressioncoarse (#11)
        64, // holdpedal (#64)
        // 65, //portamento (#65)
        // 71, //resonance (#71)
        // 74 // brightness (#74)
      ];
      // more condition to come here
      if (allowedCommonCCnumber.indexOf(number) >= 0) {
        const isModulationwheelcoarse = number === 1;
        const isVolumecoarse = number === 7;
        const isHoldpedal = number === 64;
        let channelTargetArr =
          isModulationwheelcoarse
            ? channelArr.modulationArr
            : isHoldpedal
              ? channelArr.holdpedalArr
              : number === 11
                ? channelArr.expressionArr : channelArr.isEnabledArr;

        if (channelTargetArr.length > 0) {
          output.sendControlChange(number, value, isSongMode ? [selectedChannel] : channelTargetArr);
          self.setState({ midiEvent: isSongMode ? [selectedChannel] : channelTargetArr }); // send back to parent component 
          if (isModulationwheelcoarse) self.setState({ sliderModulationValue: value })
          if (isVolumecoarse) self.setState({ sliderVolumeValue: value })

          if (isDevelopment)
            console.log(type, "channel", isSongMode ? [selectedChannel] : channelTargetArr, name, value);
        }

      } else {
        if (isDevelopment) console.log("[Blocked]", type, name, value);
      }
    } // isEventControlChange

    if (isEventProgramChange) {
      const { value } = e;
      // output.sendProgramChange(value, 1)
      if (isDevelopment) console.log(type, "channel TBC", value);
    } // isEventProgramChange
  } else {
    console.log("[ERR] Active device output not found.");
  } // isOutputActive

  // } //isValidEventType
};

/*
Helpers
*/

export const initialiseDevicesInputsOutputs = (
  self,
  newIndex,
  type
) => {

  // Get the first real device
  // const input = DEVICES_INPUTS.filter((input) => !!input.manufacturer)[0];
  // const output = DEVICES_OUTPUTS.filter((input) => !!input.manufacturer)[0];

  // Get all devices

  const input = type === "input" ? DEVICES_INPUTS[newIndex] : DEVICES_INPUTS[self.state.devicesInputsActive];
  const output = type === "output" ? DEVICES_OUTPUTS[newIndex] : DEVICES_OUTPUTS[self.state.devicesOutputsActive];

  if (input && output) {

    if(!self.state.isDevelopment) sendEvent( "Devices", [input.name,input.manufacturer,input.id,output.id].toString() , type === "input" ? "connected" : "disconnected");

    input.removeListener();

    // Listen for a 'note on' message on all channels
    input.addListener("noteon", "all", function (e) {
      // console.log("Received 'noteon' message (" + e.note.name + e.note.octave + ").")
      processMIDI(e, output, self);
    });

    // Listen for a 'note off' message on all channels
    input.addListener("noteoff", "all", function (e) {
      // console.log("Received 'noteoff' message (" + e.note.name + e.note.octave + ").");
      processMIDI(e, output, self);
    });

    // Listen to pitch bend message on channel 3
    input.addListener("pitchbend", "all", function (e) {
      // console.log("Received 'pitchbend' message.", e);
      processMIDI(e, output, self);
    });

    // Listen to control change message on all channels
    input.addListener("controlchange", "all", function (e) {
      // console.log("Received 'controlchange' message.", e);
      processMIDI(e, output, self);
    });

    input.addListener("programchange", "all", function (e) {
      // console.log("Received 'programchange' message.", e);
      processMIDI(e, output, self);
    });

    // input.addListener("channelmode", "all", function(e) {
    // console.log("Received 'controlchange' message.", e);
    //   processMIDI(e, output, self);
    // });

    // input.addListener("midimessage", "all", function(e) {
    // console.log("Received 'controlchange' message.", e);
    // processMIDI(e, output, self);
    // });

  } // input

}; // initialiseDevicesInputsOutputs


export function sendPlayNoteFromMidiPlayer(note, channel) {

  const {midi, velocity, duration} = note;

  const { isEnabledWithVoiceArr } = SELF.state.channelArr;
  const isActive = isEnabledWithVoiceArr.indexOf(channel) > -1;

  const newNote = (channel !== 10) ? midi + globalCombinedTranspositionValue : midi;

  const devicesOutputsActive = SELF.state.devicesOutputsActive
  if (devicesOutputsActive >= 0) {

    const output = DEVICES_OUTPUTS[devicesOutputsActive];
    if (output) {

      if(isActive) output.playNote(
        newNote, // note
        [channel], 
        { velocity, duration: duration * 1000 }
      );

      // setMidiEvent([channel]);

    } // output
  }
}

export function sendControlChangeFromMidiPlayer(cc, rawValue, channel) {
  const devicesOutputsActive = SELF.state.devicesOutputsActive
  if (devicesOutputsActive >= 0) {

    const output = DEVICES_OUTPUTS[devicesOutputsActive];

    if (output) {
      const value = Math.floor(convertRange(rawValue,[0,1],[DEFAULT_CONFIG.defaultMidiRange[0], DEFAULT_CONFIG.defaultMidiRange[1]]))
      output.sendControlChange(
        +cc,
        value,
        channel
      );

      // setMidiEvent([channel]);

    } // output
  }
}


export function convertVoiceToObject(value, type) {
  let obj = {};
  if(value) {
    value.split(";").map((d) => {
      const keypair = d.split("=");
      const key = keypair[0];
      const value = keypair[1];
      obj[key] = ["m", "l", "p"].indexOf(key) > -1 ? value * 1 : value;
      return false
    });
    if (type === 'dropdown') {
      return {
        label: `${obj.d ? "[" + obj.d + "] " : ""}${obj.g}:${String(obj.p).padStart(3, "0")} ${obj.n}`,
        value,
      };
    } else {
      return obj
    }
  }
};

export function sendProgramChangeOfVoice(voice, channel, devicesOutputsActive, getMidiEvent) {

  const voiceObj = convertVoiceToObject(voice)

  if (devicesOutputsActive >= 0) {
    // https://github.com/djipco/webmidi/issues/57
    const output = WebMidi.outputs[devicesOutputsActive];
    if (output) {

      output
      .sendControlChange(0, voiceObj.m)  // bank B
      .sendControlChange(32, voiceObj.l)  // bank B
      .sendProgramChange(voiceObj.p, channel); //
      // console.log(`sendControlChange: CC0 ${voiceObj.m}, CC32 ${ voiceObj.l}, sendProgramChange: ${voiceObj.p}, channel ${channel}`);

      // getMidiEvent({type: "programchange", program: voiceObj.p, channel });
      getMidiEvent([channel]);

    } // output
  }

}


export function sendPanicSignal(devicesOutputsActive, getMidiEvent) {

  if (devicesOutputsActive >= 0) {
    const output = WebMidi.outputs[devicesOutputsActive];
    if (output) {

      output.stopNote("all", "all");
      output.setPitchBendRange(0, 0, "all");
      output.stopNote([lastNote, newNote],"all");
      getMidiEvent("all"); // {type: "stopnote", value: "all", channel: "all"}

    } // output
  }

}


export function sendControlChange(value, channel, type, devicesOutputsActive, getMidiEvent) {

  if (devicesOutputsActive >= 0) {
    const output = WebMidi.outputs[devicesOutputsActive];
    if (output) {
      output.sendControlChange(type, value, channel);
      getMidiEvent(channel !== "all" ? [channel] : channel); //{type, value, channel}

    } // output
  }

}


// https://www.cs.princeton.edu/courses/archive/fall07/cos109/bc.html
export function sendChannelMode(value, channel, type, devicesOutputsActive, getMidiEvent) {

  if (devicesOutputsActive >= 0) {
    const output = WebMidi.outputs[devicesOutputsActive];
    if (output) {
      // console.log("sendChannelMode", type, value, channel)
      output.sendChannelMode(type, value, channel);
      getMidiEvent("all"); //{type, value, channel}

    } // output
  }

}



export function sendPitchBendRangeMessage(value, devicesOutputsActive, getMidiEvent, pitchbendArr) {

  if (devicesOutputsActive >= 0) {
      const output = WebMidi.outputs[devicesOutputsActive];
      if (output) {
        output.setPitchBendRange(value[0], 0, pitchbendArr);
        getMidiEvent(pitchbendArr); //{value, channel}
      } // output
  }

}



export function sendPitchBendMessage(value, devicesOutputsActive, getMidiEvent, pitchbendArr) {

  if (devicesOutputsActive >= 0) {
      const output = WebMidi.outputs[devicesOutputsActive];
      if (output) {
        output.sendPitchBend(value, pitchbendArr);
        getMidiEvent(pitchbendArr); //{value, channel}
      } // output
  }

}




export function sendMasterTuningMessage(value, devicesOutputsActive, getMidiEvent, arr) {
  
  if (devicesOutputsActive >= 0) {
    const output = WebMidi.outputs[devicesOutputsActive];
    if (output) {
      output.setMasterTuning(value, arr);
      getMidiEvent(arr); //{value, channel}
    } // output
  }

}



export function sendSysexMessage(selectedSysexOptionObj, devicesOutputsActive, getMidiEvent, arr) { 
  if (devicesOutputsActive >= 0) {
    const output = WebMidi.outputs[devicesOutputsActive];
    if (output) {
      const { data, manufacturer } = selectedSysexOptionObj;
      const dataObj = data.split(";").map(d => hexToDec(d));
      let newObj = {
        ...selectedSysexOptionObj,
        data: dataObj,
        manufacturer: manufacturer !== undefined ? manufacturer : dataObj[0]
      }
      output.sendSysex(newObj.manufacturer, newObj.data);
      getMidiEvent(arr); //{value, channel}
    } // output
  }
}



/// https://stackoverflow.com/questions/41134365/formula-to-create-simple-midi-velocity-curves

export function ConvertMidiValue(value, deviation) {
  if (deviation < -100 || deviation > 100)
    console.log("Value must be between -100 and 100", "deviation");

  let minMidiValue = 0;
  let maxMidiValue = 127;
  let midMidiValue = 63.5;

  // This is our control point for the quadratic bezier curve
  // We want this to be between 0 (min) and 63.5 (max)
  let controlPointX = midMidiValue + ((deviation / 100) * midMidiValue);

  // Get the percent position of the incoming value in relation to the max
  let t = value / maxMidiValue;

  // The quadratic bezier curve formula
  // B(t) = ((1 - t) * (1 - t) * p0) + (2 * (1 - t) * t * p1) + (t * t * p2)

  // t  = the position on the curve between (0 and 1)
  // p0 = minMidiValue (0)
  // p1 = controlPointX (the bezier control point)
  // p2 = maxMidiValue (127)

  // Formula can now be simplified as:
  // B(t) = ((1 - t) * (1 - t) * minMidiValue) + (2 * (1 - t) * t * controlPointX) + (t * t * maxMidiValue)

  // What is the deviation from our value?
  let delta = Math.round((2 * (1 - t) * t * controlPointX) + (t * t * maxMidiValue));

  return (value - delta) + value;
}




export function getCombinedGlobalAndLayerValue(octave, transpose) {
  let oValue = 0; let tValue = 0;
  oValue = octave !== undefined ? typeof octave === 'object' ? octave[0] * 12 : octave * 12 : 0
  tValue = transpose !== undefined ? typeof transpose === 'object' ? transpose[0] : transpose : 0;
  return oValue + tValue
}

