//@flow

import React, { Component } from "react";
import { makeid } from "./../../../utils/helpers";
import "./SwitchButton.scss";

//
// Types
// ----------------

type Props = {
  setValue?: number | string,
  value: boolean,
  label:? string
}

type State = {
  value?: boolean
}

class SwitchButton extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
    };
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({ value })
  }

  componentDidUpdate(props) {
    if(this.state.value !== props.value) {
      this.setState({ value: props.value });
    }
  }

  handleChange = (e) => {
    let value = e;
    let newValue = !value;
    this.setState({ value: newValue })
    this.props.setValue(newValue);
  }

  checkValue = (e) => {
    return this.state.value
  }

  render() {
    const { value } = this.state;
    const { label } = this.props;

    const genId = makeid(4);

    return (
      <div className={`SwitchButton`}>
        <form onSubmit={e => { e.preventDefault(); }}>
          <input type="checkbox" 
          name={`${genId}`}
          value={value} 
          checked={this.checkValue(value)} 
          readOnly/>
          <label 
            htmlFor={`${genId}`} 
            onClick={() => {
                      this.handleChange(value);
                    }}>{label}</label>
        </form>
      </div>
    );
  }
}

export default SwitchButton;
