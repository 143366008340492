// @flow

import React, { Component } from "react";
import { decryptData, saveDataToServer, makeid } from "../../../utils/helpers";
import { Midi } from "@tonejs/midi";
import { DEFAULT_CONFIG } from "../../organism/MidiStage/constant";

//
// Types
// ----------------

type Props = {
  setData: Function,
  gm: Array
}

type State = {
  percentages?: number
}


class UploadMidiFile extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      percentages: 0,
    };
    this.gm = this.props.gm
    this.gmProgramArr = this.props.gm.map(v => v.program)
  }



  processData = (midiData, filename) => {

    let self = this;

    const midi = new Midi(midiData);

    let layers = [];
    if (midi) {

      midi.tracks.forEach(async (track) => {

        const { channel, instrument, notes } = track
        const { number, percussion } = instrument

        if (number !== undefined && notes.length > 0) {

          let newLayer = { ...DEFAULT_CONFIG.defaultLayer };
          const idx = self.gmProgramArr.indexOf(number.toString());
          if (idx > -1) {
            const voice = self.gm[idx];
            newLayer.channel = channel + 1
            newLayer.voice = voice.value

            if (percussion) {
              // drum channel 10, do not send PC message
              newLayer.percussion = percussion
              newLayer.voice = "d=GM;m=0;l=0;g=P;p=0;n=Drum kit"
            }
            layers.push(newLayer);
          }

        }

      }); // layers end

    }

    let newObj = {
      layers,
      midiData,
      name: filename,
      type: "song"
    }

    self.props.setData(newObj);

  }



  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Typed_arrays
  handleFileUpload = (e) => {
    if (e.target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(e.target.files[0]);
      fileReader.filename = e.target.files[0].name;

      let output;
      fileReader.onload = (ev) => {
        let arrayBuffer = new Uint8Array(ev.target.result);
        output = arrayBuffer;
        const filename = ev.target.filename;
        this.processData(output, filename)
      };
    }
  };

  render() {

    const id = makeid(4);
    return (
      <div
        className="upload-midi-file"
      // onDrop={this.onDropHandler}
      // onDragOver={this.dragOverHandler}
      >
        <label className="button tertiary" htmlFor={`myinput_${id}`} title="Upload a MIDI file (.mid)">Song</label>
        <input id={`myinput_${id}`} type="file" onChange={this.handleFileUpload} accept="audio/midi" />
      </div>
    );
  }
}

export default UploadMidiFile;
