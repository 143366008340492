//@flow

import React, { Component } from "react";
import { makeid } from "./../../../utils/helpers";
import "./FancyRadio.scss";

//
// Types
// ----------------

type Props = {
  checkedOption?: number | string,
  title?: String,
  options: Array,
  defaultValues?: string | Number,
  values?: string | Number,
  onReset?: Function,
  onFinalChange: Function
}

type State = {
  checkedOption?: number | string
}

class FancyRadio extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      checkedOption: this.props.checkedOption,
    };
  }

  handleChange(e) {
    this.props.onFinalChange(e.target.value);
    this.setState({ checkedOption: e.target.value });
  }

  syncToGlobal(e) {
    this.props.syncToGlobal(e)
  }

  render() {
    const {
      defaultValues,
      values,
      title,
      options,
      onReset,
      globalValues, 
      layerValues
    } = this.props;

    const isResettable = typeof onReset === 'function' && onReset !== undefined;

    const hasGlobalValues = globalValues !== undefined ? globalValues : false;

    const isGlobalValuesUsed = layerValues === undefined && globalValues;

    const checkedValue = (str) => {
      return values !== undefined ? values === str : defaultValues === str;
    };

    const genId = makeid(4);

    return (
      <div className="fancy-radio">
        
        {hasGlobalValues && <span className={`has--global-value ${isGlobalValuesUsed ? "global-value-used":""}`} onClick={this.syncToGlobal}>+</span>}

        {title && <div className={`title ${isResettable ? "is--resettable" : ""}`} onClick={(e)=> isResettable ? this.props.onReset(title) : {}}>{title}</div>}

        <form id={genId} onChange={(e) => {
          this.handleChange(e);
        }} onSubmit={e => { e.preventDefault(); }}>
          {typeof options === 'object' && options.length > 0 &&
            options.map((d, i) => {
              const value = d.name;
              return (
                <React.Fragment key={i}>
                  {(
                    <>
                      <input
                        type="radio"
                        name={`${genId}`}
                        id={`${genId}_${i}`}
                        value={value}
                        checked={checkedValue(value)}
                        readOnly
                      />
                      <label
                        htmlFor={`${genId}_${i}`}
                        title={value}
                      >
                        {value}
                      </label>
                    </>
                  )}
                </React.Fragment>
              )}
            )}

        </form>
       
      </div>
    );
  }
}

export default FancyRadio;
