//@flow

import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { hexToDec } from "./../../../utils/helpers";
import { convertVoiceToObject, sendProgramChangeOfVoice, sendSysexMessage } from "./../../organism/MidiStage/helpers/webmidi-helpers";
import "./BankSelectDropdown.scss";

//
// Types
// ----------------

type Props = {
  ready: boolean,
  devicesOutputsActive?: number,
  width: number,
  dropdownData?: Array,
  customvoices?: string,
  voice?: string,
  setData: Function,
  onMenuClose: Function,
  channel: Number,
  setMidiEvent: Function,
  selectedPreset: number
}

type State = {
  isReady: boolean,
  voice?: string,
  selectedObj?: Object,
  inputValue?: string,
  selectedPreset: number
}

/*
Helper
*/
const getAllVoices = (dropdownData) => {
  let arr = []
  dropdownData.forEach(g => {
    g.options.forEach(d => arr.push(d));
  });
  return arr
}

class BankSelectDropdown extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      voice: this.props.voice,
      selectedObj: null,
      inputValue: "",
      data: this.props.data,
      selectedPreset: this.props.selectedPreset
    };
  }

  componentDidMount() {
    const { dropdownData, voice } = this.props;
    if (dropdownData.length > 0) {
      this.setState({ isReady: true }, () => {
        this.initVoiceValue(voice, dropdownData);
      });
    }
  }

  componentWillReceiveProps(newProps) {

    if (this.state.isReady && this.state.voice !== newProps.voice) {

      const { voice, channel, devicesOutputsActive } = newProps;

      if (voice) {
        const selectedObj = convertVoiceToObject(voice, 'dropdown');

        this.setState({ voice, selectedObj }, () => {

          // const setMidiEvent = (e) => {
          //   this.props.setMidiEvent([channel]);
          // }
          // if(newProps.ready) sendProgramChangeOfVoice(selectedObj.value, channel, devicesOutputsActive, setMidiEvent);

        });
      } else {
        this.setState({ selectedObj: null })
      }
    }
  }

  initVoiceValue = (propsVoice, dropdownData) => {

    const allVoices = getAllVoices(dropdownData);

    const { voice } = this.state;
    const newVoice = voice !== propsVoice ? propsVoice : voice;

    let newSelectedObj = null; // reset
    const { selectedObj } = this.state;

    if (newVoice) {
      if (allVoices.length > 0) {
        // find from custom voices;

        allVoices.forEach((d, i) => {
          if (d.value === newVoice) {
            newSelectedObj = d;
            this.setState({ selectedObj: newSelectedObj });
            return
          }
        });

        if (selectedObj === undefined) {
          // feed manually from data
          newSelectedObj = convertVoiceToObject(newVoice, 'dropdown');
          this.setState({ selectedObj: newSelectedObj });
          return
        }
      }


    }
  }

  handleChange = (e) => {

    const { data, selectedPreset, channel } = this.props;
    const activeData = data[selectedPreset];
    const activeLayersData = activeData.layers;
    let newActiveLayersData = [];
    let newLayerObj;
    activeLayersData.forEach(layer => {
      newLayerObj = { ...layer }
      if (layer.channel === channel) {
        newLayerObj.voice = e.value;
      }
      newActiveLayersData.push(newLayerObj)
    });

    let newData = [];
    data.forEach((d, i) => {
      let obj = { ...d };
      if (i === selectedPreset) {
        // layer with new voice
        obj.layers = newActiveLayersData
      }
      newData.push(obj);
    });

    this.setState({ value: e });
    this.props.setData(newData);

    const setMidiEvent = (e) => {
      this.props.setMidiEvent(e);
    }

    const { ready, devicesOutputsActive } = this.props;
    
    if (ready) {
      sendProgramChangeOfVoice(e.value, channel, devicesOutputsActive, setMidiEvent);
    }

  };

  filterInput = (selectedCat) => {
    const { dropdownData } = this.props;
    const { inputValue } = this.state;
    const filteredGroupOptions = dropdownData;
    if (inputValue.length > 0) {
      filteredGroupOptions.map(group => {
        group.options = group.options.filter((d => d.label.toLowerCase().includes(selectedCat.toLowerCase())));
      });
    }
    return filteredGroupOptions
  }

  loadOptions = (input, callback) => {
    this.setState({ inputValue: input })
    if (input.length > 2) {
      setTimeout(() => {
        callback(this.filterInput(input));
      }, 20);
    }
  };

  setResultsLimit = () => {
    const size = 20;
    const { dropdownData } = this.props;
    let newDropdownData = dropdownData;
    if (newDropdownData && newDropdownData.length > 1) {
      newDropdownData[0].options.slice(0, size);
    }
    return newDropdownData;
  };

  noOptionsMessage = () => {
    return null;
  };

  handleInputChange = (e, metaAction) => {
    const { action } = metaAction;
    if (action === 'menu-close') {
      // reset menu
      this.setState({ inputValue: "" })
      this.props.onMenuClose(action)

    }
  }

  formatGroupLabel = (data) => {
    return (
      <div className="group-heading__inner">
        <span>{data.label}</span>
        <span className="group-badge">{data.options.length}</span>
      </div>
    )
  };

  render() {
    const { width } = this.props;
    const customStyles = {
      container: (base) => ({
        ...base,
        width: width - 44
      }),
      menuPortal: base => ({
        // this just updates the menuPortal style to trigger a rerender
        ...base,
      })
    };


    return (
      <div className="react-select__wrapper">
        <AsyncSelect
          value={this.state.selectedObj}
          loadOptions={this.loadOptions}
          defaultOptions={this.setResultsLimit()}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          formatGroupLabel={this.formatGroupLabel}
          classNamePrefix={"react-select"}
          placeholder={"Select a voice..."}
          styles={customStyles}
          filterOption={() => ({
            ignoreCase: false,
            ignoreAccents: false,
            trim: false,
            matchFromStart: false
          })}
        // menuIsOpen={true}
        />
      </div>
    );
  }
}

export default BankSelectDropdown;
