//@flow

import React, { Component } from "react";
import "./MidiEventsIndicator.scss";

//
// Types
// ----------------

type Props = {
  input: any, 
  label?: string 
}

type State = {}

class MidiEventsIndicator extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.myLight = React.createRef();
    this.state = {
    };
  }

  render() {
    
    const { input, label } = this.props;
    let myClassName = "midi-events-indicator";
    if (input === true) {
      if (this.myLight.current) {
        this.myLight.current.classList.add("is--on");
        setTimeout(() => {
          if(this.myLight.current !== null && this.myLight.current.classList.value.indexOf("is--on") > -1) this.myLight.current.classList.remove("is--on");
        }, 10);
      }
    }

    return (
      <div className="d-flex flex-column align-items-center justify-content-center"> 
        <div className={myClassName} ref={this.myLight}></div>
        {label && <div className="midi-events-indicator__label">{label}</div>}
      </div>
    );
  }
}

export default MidiEventsIndicator;
