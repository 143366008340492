//@flow

import React, { Component } from "react";
import Loader from "../../atom/Loader/Loader";
import { debounce } from "lodash";
import { sendEvent, slugify } from "./../../../utils/helpers";
import { initialiseWebMidi, sendPanicSignal, sendControlChange, initialiseDevicesInputsOutputs, sendChannelMode, sendPitchBendMessage } from "./helpers/webmidi-helpers";
import generateChannelArr from "./helpers/generate-channel-arr";
import { updateSingleLayerData, deleteSingleLayerData } from "./helpers/handle-layer-data";
import { updateSingleGlobalData, deleteSingleGlobalData } from "./helpers/handle-global-data";
import { generateDropdownData } from "./helpers/generate-dropdown";
import initialiseNewData from "./helpers/initialise-new-data";

import { DEFAULT_CONFIG } from "./constant";
import RadioSelectBox from "./../../atom/RadioSelectBox/RadioSelectBox";
import DownloadConfigFile from "../../molecules/DownloadConfigFile/DownloadConfigFile";
import UploadConfigFile from "../../molecules/UploadConfigFile/UploadConfigFile";
import TwoThumbsRangeSlider from "./../../atom/TwoThumbsRangeSlider/TwoThumbsRangeSlider";
import SingleThumbRangeSlider from "./../../atom/TwoThumbsRangeSlider/SingleThumbRangeSlider";
import SingleThumbVerticalSlider from "./../../atom/TwoThumbsRangeSlider/SingleThumbVerticalSlider";
import LayerToggleSwitches from "../../molecules/LayerToggleSwitches/LayerToggleSwitches";
import BankSelectDropdown from "../../molecules/BankSelectDropdown/BankSelectDropdown";
import "./MidiStage.scss";
import NoticeBox from "./../../atom/Noticebox/Noticebox";
import SwitchButton from "../../atom/SwitchButton/SwitchButton";
import SectionBox from "../../atom/SectionBox/SectionBox";
import UserPresetButtons from "../../molecules/UserPresetButtons/UserPresetButtons";
import MidiStageLogoSrc from "./midi-stage.svg";
import ErrorSrc from "./404.jpg";
import MyButton from "../../atom/Button/Button";
import CustomVoicesDropdown from "../../molecules/BankSelectDropdown/CustomVoicesDropdown";
import CookieConsent from "../../molecules/CookieConsent/CookieConsent";
import FancyRadio from "../../atom/FancyRadio/FancyRadio";

import Player from "../MidiPlayer/MidiPlayer";

//
// Types
// ----------------

export type Data = {
  config: Object,
  data: Object<Array<Value<Array>|Value<Uint8Array>|Value<string>>>
}

type Props = {
  config?: Object,
  customVoicesOptions?: Array<Object>,
  data?: Array<Data>,
  faq?: Array,
  gm?: Array,
  isMobile: boolean
}

type State = {
  ready: boolean,
  midiEvent?: Array,
  devicesInputs?: any,
  devicesInputsActive: number,
  devicesOutputs?: any,
  devicesOutputsActive?: number,
  config?: Object,
  data: Array<Data>,
  gm?: Array,
  channelArr: Object,
  isMobile: boolean,
  customvoices?: string,
  customVoicesOptions: Array,
  isDevelopment: boolean,
  isSettingsOpen: boolean,
  reactSelectWidth: number,
  previousPreset?: number,
  selectedPreset: number,
  selectedChannel: number,
  sliderPitchbendValue: number,
  sliderVolumeValue: number,
  sliderModulationValue: number,
  velocityCurveDeviation: number,
  groupedOptions?: Array,
  faq?: Array,
  isDropdownDataReady: boolean,
  haveSysexControl: boolean,
  sysexControlArr?: Array
}



class MidiStage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.reactSelectDiv = React.createRef();
    this.state = {
      ready: false,
      midiEvent: [],
      devicesInputs: [],
      devicesInputsActive: 0,
      devicesOutputs: [],
      devicesOutputsActive: 0,
      config: this.props.config,
      data: this.props.data,
      gm: this.props.gm,
      channelArr: {},
      isMobile: this.props.isMobile,
      customvoices: this.props.config.customvoices || undefined,
      customVoicesOptions: this.props.customVoicesOptions,
      isDevelopment: process.env.NODE_ENV === "development" || this.props.isDevelopment,
      isSettingsOpen: false,
      reactSelectWidth: 0,
      previousPreset: null,
      selectedPreset: 0,
      selectedChannel: 1,
      sliderPitchbendValue: 0,
      sliderVolumeValue: 127,
      sliderModulationValue: 0,
      velocityCurveDeviation: 0,
      groupedOptions: [],
      faq: this.props.faq,
      isDropdownDataReady: false,
      haveSysexControl: false,
      sysexControlArr: []
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {

    let self = this; // important
    initialiseWebMidi(self, self.state);
    window.addEventListener('resize', debounce(this.handleResize, 30));
    setTimeout(() => {
      this.handleResize();
      initialiseNewData(self);
      generateDropdownData(self);
    }, 0)

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = (e) => {
    const rsWidth = this.reactSelectDiv
    if (rsWidth && rsWidth.current !== null) {
      const reactSelectWidth = rsWidth.getBoundingClientRect().width;
      this.setState({ reactSelectWidth });
    }
  }

  getDevicesInputsActive = (e) => {
    let self = this;
    this.setState({ devicesInputsActive: e });
    initialiseDevicesInputsOutputs(self, e, "input");
  }

  getDevicesOutputsActive = (e) => {
    let self = this;
    this.setState({ devicesOutputsActive: e });
    initialiseDevicesInputsOutputs(self, e, "output");
  }

  setData = (data, selectedPreset) => {
    const _selectedPreset = selectedPreset ? selectedPreset : this.state.selectedPreset;
    this.setState({
      data,
      channelArr: generateChannelArr(data, _selectedPreset)
    });
  }

  setMidiEvent = (e) => {
    this.setState({ midiEvent: e })
  }

  setConfig = (appData) => {

    let self = this;

    const { selectedPreset, dropdownData } = this.state;
    const { config, data } = appData;

    let newDropdownData = dropdownData;

    if (config.customvoices) {
      newDropdownData = generateDropdownData(self, config.customvoices);
    }

    const _selectedPreset = config.selectedPreset ? config.selectedPreset : selectedPreset;

    let newData = data;
    data.forEach(d => {
      let newObj = d;
      if (d.midiData && typeof d.midiData === 'object') {
        newObj.midiData = Uint8Array.from(Object.values(d.midiData));
      }
    })

    this.setState({
      config,
      data: newData,
      selectedPreset: _selectedPreset,
      channelArr: generateChannelArr(data, _selectedPreset),
      customvoices: config.customvoices,
      dropdownData: newDropdownData
    }, () => {
      initialiseNewData(self);
    });
  }



  handlePanic = () => {
    const { devicesOutputsActive } = this.state;
    const getMidiEvent = (e) => {
      this.setState({ midiEvent: e })
    }
    sendPanicSignal(devicesOutputsActive, getMidiEvent);
  }

  setSwitchValue = (type, i, e) => {
    const { data, selectedPreset } = this.state;

    let newLayerData = data[selectedPreset].layers[i];
    newLayerData[type] = e;

    let newData = data;
    newData[selectedPreset].layers[i] = newLayerData;
    this.setState({
      data: newData,
      channelArr: generateChannelArr(newData, selectedPreset)
    });
  }

  setSelectedPreset = (selectedPreset) => {
    let self = this;
    let newConfig = this.state.config;
    newConfig.selectedPreset = selectedPreset;

    this.setState({
      selectedPreset,
      selectedChannel: 0,
      config: newConfig,
      channelArr: generateChannelArr(this.state.data, selectedPreset)
    }, () => {
      initialiseNewData(self);
    });

  }

  setPreviousPreset = (previousPreset) => {
    this.setState({ previousPreset });
  }


  setCustomVoices = (customvoices) => {

    if(customvoices === undefined) return false;

    let self = this;
    let newConfig = this.state.config;
    newConfig.customvoices = customvoices;

    this.setState({ config: newConfig, customvoices }, () => {
      generateDropdownData(self);
    })

    sendEvent("dropdown", "customvoices", customvoices); // tracking
  }


  setSelectedChannel = (channel) => {
    const newSelectedChannel = channel;
    this.setState({ selectedChannel: newSelectedChannel })
  }


  render() {

    const { ready, isMobile, config, data, channelArr, selectedPreset, midiEvent, devicesInputs, devicesInputsActive, devicesOutputsActive, customvoices, reactSelectWidth, isSettingsOpen, groupedOptions, isDevelopment, haveSysexControl, sysexControlArr } = this.state;

    let self = this;

    const isDataValid = data && data.length > 0 && data[0].layers;
    if (isDataValid) {

      const appData = {
        config,
        data
      }

      const _selectedPreset = selectedPreset > data.length - 1 ? selectedPreset - 1 : selectedPreset; 
      const activeData = data[_selectedPreset];

      const isSongType = activeData && activeData.type !== undefined && activeData.type !== "" && activeData.type === 'song' ? true : false;

      if (isDevelopment) console.log("appData", appData);

      return (
        <>

          {(
            <div className="container-fluid dark-theme">
              <Loader loaded={reactSelectWidth > 0} />
              <header>
                <div className="row no-gutters pt-2">
                  <div className="col d-flex align-items-end justify-content-between justify-content-sm-start mb-2">

                    <div className="logo">
                      <a href="https://midistage.com">
                        <img src={MidiStageLogoSrc} width="140" alt="MidiStage" />
                      </a>
                    </div>
                    <div className="d-flex align-items-end justify-content-between">
                      <DownloadConfigFile data={appData} />
                      <UploadConfigFile setConfig={this.setConfig} />
                      <div className="d-flex align-items-end"><button className="mybutton primary ml-2" onClick={(e) => initialiseNewData(self)}>Init</button></div>
                      <div className="d-flex align-items-end"><button className="mybutton warning ml-2" onClick={this.handlePanic}>Panic</button></div>
                    </div>
                  </div>
                  <div className="col mb-2">
                    <div className="d-flex align-items-end justify-content-end">
                      <SectionBox title="inputs" customStyle="mr-1">
                        <RadioSelectBox
                          setCheckedOptions={this.getDevicesInputsActive}
                          options={devicesInputs.map(d => d.id) || []}
                          optionNames={devicesInputs.map(d => `${d.name} ${d.manufacturer}`)}
                          checkedOptions={devicesInputsActive}
                        />
                      </SectionBox>
                      <SectionBox title="outputs" customStyle="mx-1">
                        <RadioSelectBox
                          setCheckedOptions={this.getDevicesOutputsActive}
                          options={devicesInputs ? devicesInputs.map(d => d.id) : []}
                          optionNames={devicesInputs ? devicesInputs.map(d => `${d.name} ${d.manufacturer}`) : []}
                          checkedOptions={devicesOutputsActive}
                        />
                      </SectionBox>
                      <button className={`mybutton ml-1 ${isSettingsOpen ? "is--active" : ""}`} alt="Global settings" title="Global settings" onClick={(e) => {
                        this.setState({ isSettingsOpen: !isSettingsOpen })
                      }}>
                        <svg height="1em" width="1em" focusable="false">
                          <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-sliders"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </header>
              <main>

                {!ready && (
                  <div className="row">
                    <div className="col">
                      <div className="blocker">

                        <img src={ErrorSrc} alt="error" width="120" />
                        <h3>
                          MidiStage cannot be enabled.
                        </h3>
                        <p>
                          Please check browser compatibility list <a href="https://caniuse.com/midi" target="_blank" rel="noreferrer">here</a>.</p>
                        <p><strong>iOS (loading file only)</strong>: Use  <a href="https://apps.apple.com/us/app/web-midi-browser/id953846217" target="_blank" rel="noreferrer">Web MIDI Browser</a> from the App Store.</p><p>Use desktop platform when preparing your file.</p>
                      </div>
                    </div>
                  </div>
                )}

                {isSettingsOpen && <div className="row my-2">

                  <div className="col-6 col-sm-3">
                    <SectionBox customStyle="mb-2">
                      <div className="d-flex">
                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={
                              (e) => {
                                sendPitchBendMessage(e[0], devicesOutputsActive, this.setMidiEvent, channelArr.pitchbendArr)
                              }
                            }
                            step={0.01}
                            values={[0]}
                            min={-1}
                            max={1}
                            label={isMobile ? "p.bend" : "pitchbend"}
                            onFinalChange={(e) => this.setState({
                              sliderPitchbendValue: 0
                            })}
                          />
                        </div>
                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={(value, e) => { }}
                            onFinalChange={
                              (value, e) => {
                                if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                  updateSingleGlobalData(value, 'pitchbendrange', self);

                                }
                              }
                            }
                            onReset={(e) => {
                              deleteSingleGlobalData('pitchbendrange', self);
                            }}
                            step={1}
                            defaultValues={[2]}
                            values={activeData.global !== undefined && activeData.global.pitchbendrange !== undefined ? activeData.global.pitchbendrange : [DEFAULT_CONFIG.defaultPitchBendRange[0]]}
                            min={DEFAULT_CONFIG.defaultPitchBendRange[0]}
                            max={DEFAULT_CONFIG.defaultPitchBendRange[1]}
                            label={"pb.range"}
                          />
                        </div>
                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={
                              (e) => sendControlChange(e[0], "all", "modulationwheelcoarse", devicesOutputsActive, this.setMidiEvent)
                            }
                            values={[this.state.sliderModulationValue]}
                            min={DEFAULT_CONFIG.defaultMidiRange[0]}
                            max={DEFAULT_CONFIG.defaultMidiRange[1]}
                            label={isMobile ? "mod" : "modulation"}
                            onFinalChange={(e) => {
                              if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                this.setState({ sliderModulationValue: e[0] })
                              }
                            }}
                          />
                        </div>
                      </div>
                    </SectionBox>
                  </div>

                  <div className="col-6 col-sm-1 order-sm-4">
                    <SectionBox title="" customStyle="mb-2">
                      <div className="row">
                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={
                              (e) => sendControlChange(e[0], "all", "volumecoarse", devicesOutputsActive, this.setMidiEvent)
                            }
                            values={[this.state.sliderVolumeValue]}
                            min={DEFAULT_CONFIG.defaultMidiRange[0]}
                            max={DEFAULT_CONFIG.defaultMidiRange[1]}
                            label={isMobile ? "vol" : "volume"}
                            onFinalChange={(e) => this.setState({ sliderVolumeValue: e[0] })}
                          />
                        </div>
                      </div>
                    </SectionBox>
                  </div>

                  <div className="col-12 col-sm-4 order-sm-2">
                    <SectionBox title="" customStyle="mb-2">
                      <div className="row">
                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={(e) => { }}
                            onFinalChange={
                              (e) => this.setState({ velocityCurveDeviation: e[0] })
                            }
                            onReset={(e) => this.setState({ velocityCurveDeviation: e[0] })}
                            values={[this.state.velocityCurveDeviation]}
                            min={-100}
                            max={100}
                            label={"v.curve"}
                          />
                        </div>

                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={(value, e) => {
                              if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                updateSingleGlobalData(value, 'tuning', self);
                              }
                            }}
                            onFinalChange={
                              (value, e) => {
                                if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                  updateSingleGlobalData(value, 'tuning', self);

                                }
                              }
                            }
                            onReset={(e) => {
                              deleteSingleGlobalData('tuning', self);
                            }}
                            step={0.01}
                            values={activeData.global !== undefined && activeData.global.tuning !== undefined ? activeData.global.tuning : [0]}
                            min={DEFAULT_CONFIG.defaultFineTuningRange[0]}
                            max={DEFAULT_CONFIG.defaultFineTuningRange[1]}
                            label={"fine tune"}
                          />
                        </div>

                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={(e) => { }}
                            onFinalChange={
                              (values, e) => {
                                if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                  updateSingleGlobalData(values, 'octave', self)
                                }
                              }
                            }
                            onReset={(e) => {
                              deleteSingleGlobalData('octave', self);
                            }}
                            values={activeData.global !== undefined && activeData.global.octave !== undefined ? activeData.global.octave : [0]}
                            min={DEFAULT_CONFIG.defaultOctaveRange[0]}
                            max={DEFAULT_CONFIG.defaultOctaveRange[1]}
                            label={"octave"}
                          />
                        </div>

                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={(values, e) => {
                              if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                updateSingleGlobalData(values, 'transpose', self)
                              }
                            }}
                            onFinalChange={
                              (values, e) => {
                                if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                  updateSingleGlobalData(values, 'transpose', self)
                                }
                              }
                            }
                            onReset={(e) => {
                              deleteSingleGlobalData('transpose', self);
                            }}
                            values={activeData.global !== undefined && activeData.global.transpose !== undefined ? activeData.global.transpose : [0]}
                            min={DEFAULT_CONFIG.defaultTransposeRange[0]}
                            max={DEFAULT_CONFIG.defaultTransposeRange[1]}
                            label={"transpose"}
                          />
                        </div>

                      </div>
                    </SectionBox>
                  </div>

                  <div className="col col-sm-4 order-sm-3">
                    <SectionBox title="" customStyle="mb-2">
                      <div className="row">
                        <div className="col d-flex flex-column justify-content-center align-items-center">

                          <CustomVoicesDropdown
                            ready={ready}
                            width={reactSelectWidth}
                            customvoices={this.state.customvoices}
                            dropdownData={this.state.customVoicesOptions}
                            onSubmit={this.setCustomVoices}
                            isDevelopment={isDevelopment}
                          />

                          <MyButton text={['Local On', 'Local Off']}
                            customStyle="mb-2"
                            defaultValue={true}
                            setOn={() => {
                              sendChannelMode(127, "all", 'localcontrol', devicesOutputsActive, this.setMidiEvent);
                            }}
                            setOff={() => {
                              sendChannelMode(0, "all", 'localcontrol', devicesOutputsActive, this.setMidiEvent);
                            }}
                            title="Please refer to your hardware manual."
                          />

                        </div>
                      </div>
                    </SectionBox>

                  </div>

                  <div className="col col-sm-12 order-sm-5">
                    <SectionBox title="" customStyle="mb-2">
                      <div className="row">

                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={(values, e) => { }}
                            onFinalChange={
                              (values, e) => {
                                if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                  updateSingleGlobalData(values, 'reverblevel', self)
                                }
                              }
                            }
                            onReset={(e) => {
                              deleteSingleGlobalData('reverblevel', self);
                            }}
                            values={activeData.global !== undefined && activeData.global.reverblevel !== undefined ? activeData.global.reverblevel : [0]}
                            min={DEFAULT_CONFIG.defaultMidiRange[0]}
                            max={DEFAULT_CONFIG.defaultMidiRange[1]}
                            label="reverb"
                          />
                        </div>

                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={(values, e) => { }}
                            onFinalChange={
                              (values, e) => {
                                if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                  updateSingleGlobalData(values, 'pancoarse', self)
                                }
                              }
                            }
                            onReset={(e) => {
                              deleteSingleGlobalData('pancoarse', self);
                            }}
                            defaultValues={[64]}
                            values={activeData.global !== undefined && activeData.global.pancoarse !== undefined ? activeData.global.pancoarse : [64]}
                            min={DEFAULT_CONFIG.defaultMidiRange[0]}
                            max={DEFAULT_CONFIG.defaultMidiRange[1]}
                            label="pan"
                          />
                        </div>

                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={(values, e) => { }}
                            onFinalChange={
                              (values, e) => {
                                if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                  updateSingleGlobalData(values, 'tremololevel', self)
                                }
                              }
                            }
                            onReset={(e) => {
                              deleteSingleGlobalData('tremololevel', self);
                            }}
                            values={activeData && activeData.global !== undefined && activeData.global.tremololevel !== undefined ? activeData.global.tremololevel : [0]}
                            min={DEFAULT_CONFIG.defaultMidiRange[0]}
                            max={DEFAULT_CONFIG.defaultMidiRange[1]}
                            label="tremolo"
                          />
                        </div>

                        <div className="col">
                          <SingleThumbVerticalSlider
                            setValues={(values, e) => { }}
                            onFinalChange={
                              (values, e) => {
                                if (e && e.type && (e.type === "mouseup" || e.type === "onclick" || e.type === "touchend")) {
                                  updateSingleGlobalData(values, 'choruslevel', self)
                                }
                              }
                            }
                            onReset={(e) => {
                              deleteSingleGlobalData('choruslevel', self);
                            }}
                            values={activeData.global !== undefined && activeData.global.choruslevel !== undefined ? activeData.global.choruslevel : [0]}
                            min={DEFAULT_CONFIG.defaultMidiRange[0]}
                            max={DEFAULT_CONFIG.defaultMidiRange[1]}
                            label="chorus"
                          />
                        </div>

                      </div>
                    </SectionBox>
                  </div>



                  {/* Sysex Control */}

                  {haveSysexControl && sysexControlArr.length > 0 && <div className="col-12 order-sm-6">
                    <SectionBox title={customvoices} customStyle={`mb-2 sysex-control ${slugify(customvoices)}`}>
                      <div className="row">

                        {haveSysexControl && sysexControlArr.map((group, i) => {
                          const { component, options, label, value } = group;
                          if (isDevelopment) console.log(group, options);
                          
                          // init default option
                          const optionsNames = options.map(d => d.name);
                          const defaultOption = optionsNames.filter(d => d.indexOf("*") > -1);
                          const defaultIndex = defaultOption.length === 1 ? optionsNames.indexOf(defaultOption[0]) : 0;

                          return (
                            <React.Fragment key={i}>

                              {component === 'fancyRadio' &&
                                <div className="col d-flex justify-content-center">
                                  <FancyRadio
                                    onFinalChange={(e) => {
                                      updateSingleGlobalData(e, value, self)
                                    }}
                                    onReset={(e) => {
                                      deleteSingleGlobalData(value, self)
                                    }}
                                    title={label}
                                    options={options}
                                    defaultValues={options[defaultIndex].name}
                                    values={activeData.global !== undefined && activeData.global[value] !== undefined ? activeData.global[value] : options[defaultIndex].name}
                                  />
                                </div>
                              }

                            </React.Fragment>
                          )
                        }
                        )}

                      </div>
                    </SectionBox>
                  </div>}

                  {/* end Sysex Control */}

                </div>}

                <div className="row">
                  <div className={`col`}>
                    <UserPresetButtons
                      data={data}
                      gm={this.state.gm}
                      selectedPreset={selectedPreset}
                      setSelectedPreset={this.setSelectedPreset}
                      setPreviousPreset={this.setPreviousPreset}
                      setData={this.setData}
                    />
                  </div>
                </div>

                {activeData.midiData && <Player
                  data={activeData}
                  selectedPreset={selectedPreset}
                  devicesOutputsActive={devicesOutputsActive}
                  isDevelopment={isDevelopment}
                  setMidiEvent={this.setMidiEvent}
                />}

                {<div className="row">
                  <div className="col"><div className="section-label">Channel tracks</div></div>
                </div>}

                {<LayerToggleSwitches midiEvent={midiEvent} data={data} setData={this.setData} selectedPreset={selectedPreset} />}

                {/* Piano layer */}
                <div className="piano-layers">

                  {activeData && activeData.layers.length === 0 &&
                    <div className="col text-center">Please activate a channel track.</div>
                  }

                  {activeData && activeData.layers.map((d, i) => {
                    const {
                      isEnabled,
                      velocity,
                      range,
                      expressioncoarse,
                      holdpedal,
                      modulation,
                      pitchbend,
                      pitchbendrange,
                      keyaftertouch,
                      tuning,
                      octave,
                      transpose,
                      pancoarse,
                      reverblevel,
                      tremololevel,
                      choruslevel,
                      voice,
                      channel
                    } = d;

                    return (
                      <div className={`layer ${channel === this.state.selectedChannel && isSongType ? "is--active" : ""}`} key={i}>

                        <div className="layer__inner">

                          <div className={`row d-flex align-items-center ${isEnabled ? "is--active" : ""}`}>
                            <div className="col-4 col-sm-4 order-2 order-sm-1 d-flex align-items-center">
                              <TwoThumbsRangeSlider
                                onFinalChange={
                                  (e) => updateSingleLayerData(e, i, 'range', self)
                                }
                                values={range}
                                min={DEFAULT_CONFIG.defaultMidiRange[0]}
                                max={DEFAULT_CONFIG.defaultMidiRange[1]}
                                label={"key range"}
                                displayNoteNames
                              />
                            </div>
                            <div className="col-4 col-sm-2 order-3 d-flex align-items-center">
                              <TwoThumbsRangeSlider
                                onFinalChange={
                                  (e) => updateSingleLayerData(e, i, 'velocity', self)
                                }
                                values={velocity}
                                min={DEFAULT_CONFIG.defaultMidiRange[0]}
                                max={DEFAULT_CONFIG.defaultMidiRange[1]}
                                label={"velocity"}
                              // isResettable
                              />
                            </div>
                            <div className="col-4 col-sm-2 order-4 d-flex align-items-center">
                              <SingleThumbRangeSlider
                                setValues={
                                  (e) => updateSingleLayerData(e, i, 'expressioncoarse', self)
                                }
                                onFinalChange={
                                  (e) => updateSingleLayerData(e, i, 'expressioncoarse', self)
                                }
                                // onReset={()=>{}}
                                values={expressioncoarse !== undefined ? expressioncoarse : [DEFAULT_CONFIG.defaultMidiRange[1]]}
                                defaultValues={[DEFAULT_CONFIG.defaultMidiRange[1]]}
                                min={DEFAULT_CONFIG.defaultMidiRange[0]}
                                max={DEFAULT_CONFIG.defaultMidiRange[1]}
                                label={"expression"}
                              />
                            </div>
                            <div className="col-12 col-sm-4 order-1 order-sm-2 info-section d-flex align-items-center">

                              <div className="d-flex align-items-center mb-3 mb-sm-0" style={{ width: '100%', minWidth: '100%' }} ref={(el)=> this.reactSelectDiv = el} id="react-select-div">
                                <div
                                  className={`channel-info ${isEnabled ? "is--active" : ""}`}
                                  id={"c" + channel}
                                  onClick={(e) => {
                                    const value = isEnabled;
                                    updateSingleLayerData(!value, i, 'isEnabled', self)
                                  }}
                                >
                                  {channel}
                                </div>
                                {this.state.isDropdownDataReady && groupedOptions.length > 0 && <BankSelectDropdown
                                  ready={ready}
                                  devicesOutputsActive={devicesOutputsActive}
                                  width={reactSelectWidth}
                                  dropdownData={groupedOptions}
                                  customvoices={customvoices}
                                  voice={voice}
                                  data={data}
                                  selectedPreset={selectedPreset}
                                  setData={(e) => {
                                    this.setData(e)
                                  }}
                                  onMenuClose={() => {
                                    generateDropdownData(self)
                                  }}
                                  channel={channel}
                                  setMidiEvent={this.setMidiEvent}
                                />}

                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center">

                            <div className="col-12 col-md-9 order-md-2">
                              <div className="row d-flex justify-content-between align-items-center">

                                <div className="col-4 col-md-3">
                                  <SingleThumbRangeSlider
                                    setValues={(e) => { }}
                                    onFinalChange={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'tuning', self)
                                      }
                                    }
                                    onReset={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'tuning', self)
                                      }
                                    }
                                    onSyncToGlobal={
                                      (e) => {
                                        deleteSingleLayerData(i, 'tuning', self)
                                      }
                                    }
                                    // https://www.recordingblogs.com/wiki/midi-registered-parameter-number-rpn
                                    step={0.01}
                                    values={tuning !== undefined ? tuning : [0]}
                                    min={DEFAULT_CONFIG.defaultFineTuningRange[0]}
                                    max={DEFAULT_CONFIG.defaultFineTuningRange[1]}
                                    label={"fine tuning"}
                                    globalValues={activeData.global && activeData.global.tuning !== undefined ? activeData.global.tuning : undefined}
                                    layerValues={tuning}
                                    isResettable
                                  />
                                </div>
                                <div className="col-4 col-md-3">
                                  <SingleThumbRangeSlider
                                    setValues={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'octave', self)
                                      }
                                    }
                                    onFinalChange={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'octave', self)
                                      }
                                    }
                                    onReset={(e) => {
                                      updateSingleLayerData(e, i, 'octave', self)
                                    }}
                                    onSyncToGlobal={
                                      (e) => {
                                        deleteSingleLayerData(i, 'octave', self)
                                      }
                                    }
                                    values={octave !== undefined ? octave : [0]}
                                    min={DEFAULT_CONFIG.defaultOctaveRange[0]}
                                    max={DEFAULT_CONFIG.defaultOctaveRange[1]}
                                    label={"octave"}
                                    globalValues={activeData.global && activeData.global.octave !== undefined ? activeData.global.octave : undefined}
                                    layerValues={octave}
                                  />
                                </div>
                                <div className="col-4 col-md-3">
                                  <SingleThumbRangeSlider
                                    setValues={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'transpose', self)
                                      }
                                    }
                                    onFinalChange={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'transpose', self)
                                      }
                                    }
                                    onReset={(e) => {
                                      updateSingleLayerData(e, i, 'transpose', self)
                                    }}
                                    onSyncToGlobal={
                                      (e) => {
                                        deleteSingleLayerData(i, 'transpose', self)
                                      }
                                    }
                                    values={transpose !== undefined ? transpose : [0]}
                                    min={DEFAULT_CONFIG.defaultTransposeRange[0]}
                                    max={DEFAULT_CONFIG.defaultTransposeRange[1]}
                                    label={"transpose"}
                                    globalValues={activeData.global && activeData.global.transpose !== undefined ? activeData.global.transpose : undefined}
                                    layerValues={transpose}
                                  />
                                </div>

                                <div className="col-4 col-md-3">
                                  <SingleThumbRangeSlider
                                    setValues={(e) => { }}
                                    onFinalChange={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'pitchbendrange', self)
                                      }
                                    }
                                    onReset={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'pitchbendrange', self)
                                      }
                                    }
                                    onSyncToGlobal={
                                      (e) => {
                                        deleteSingleLayerData(i, 'pitchbendrange', self)
                                      }
                                    }
                                    // https://www.recordingblogs.com/wiki/midi-registered-parameter-number-rpn
                                    step={1}
                                    defaultValues={[2]}
                                    values={pitchbendrange !== undefined ? pitchbendrange : activeData.global && activeData.global.pitchbendrange !== undefined ? activeData.global.pitchbendrange : [DEFAULT_CONFIG.defaultPitchBendRange[0]]}
                                    min={DEFAULT_CONFIG.defaultPitchBendRange[0]}
                                    max={DEFAULT_CONFIG.defaultPitchBendRange[1]}
                                    label={"p.bend range"}
                                    globalValues={activeData.global && activeData.global.pitchbendrange !== undefined ? activeData.global.pitchbendrange : undefined}
                                    layerValues={pitchbendrange}
                                    isResettable
                                  />
                                </div>

                                <div className="col-4 col-md-3">
                                  <SingleThumbRangeSlider
                                    setValues={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'pancoarse', self)
                                      }
                                    }
                                    onFinalChange={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'pancoarse', self)
                                      }
                                    }
                                    onReset={(e) => {
                                      updateSingleLayerData(e, i, 'pancoarse', self)
                                    }}
                                    onSyncToGlobal={
                                      (e) => {
                                        deleteSingleLayerData(i, 'pancoarse', self)
                                      }
                                    }
                                    defaultValues={[64]}
                                    values={
                                      pancoarse !== undefined ? pancoarse : activeData.global && activeData.global.pancoarse !== undefined ? activeData.global.pancoarse : [64]
                                    }
                                    min={DEFAULT_CONFIG.defaultMidiRange[0]}
                                    max={DEFAULT_CONFIG.defaultMidiRange[1]}
                                    label="pan"
                                    globalValues={activeData.global && activeData.global.pancoarse !== undefined ? activeData.global.pancoarse : undefined}
                                    layerValues={pancoarse}
                                  />
                                </div>

                                <div className="col-4 col-md-3">
                                  <SingleThumbRangeSlider
                                    setValues={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'tremololevel', self)
                                      }
                                    }
                                    onFinalChange={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'tremololevel', self)
                                      }
                                    }
                                    onReset={(e) => {
                                      updateSingleLayerData(e, i, 'tremololevel', self)
                                    }}
                                    onSyncToGlobal={
                                      (e) => {
                                        deleteSingleLayerData(i, 'tremololevel', self)
                                      }
                                    }
                                    values={
                                      tremololevel !== undefined ? tremololevel : activeData.global && activeData.global.tremololevel !== undefined ? activeData.global.tremololevel : [0]
                                    }
                                    min={DEFAULT_CONFIG.defaultMidiRange[0]}
                                    max={DEFAULT_CONFIG.defaultMidiRange[1]}
                                    label="tremolo"
                                    globalValues={activeData.global && activeData.global.tremololevel !== undefined ? activeData.global.tremololevel : undefined}
                                    layerValues={tremololevel}
                                  />
                                </div>

                                <div className="col-4 col-md-3">
                                  <SingleThumbRangeSlider
                                    setValues={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'reverblevel', self)
                                      }
                                    }
                                    onFinalChange={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'reverblevel', self)
                                      }
                                    }
                                    onReset={(e) => {
                                      updateSingleLayerData(e, i, 'reverblevel', self)
                                    }}
                                    onSyncToGlobal={
                                      (e) => {
                                        deleteSingleLayerData(i, 'reverblevel', self)
                                      }
                                    }
                                    values={
                                      reverblevel !== undefined ? reverblevel : activeData.global && activeData.global.reverblevel !== undefined ? activeData.global.reverblevel : [0]
                                    }
                                    min={DEFAULT_CONFIG.defaultMidiRange[0]}
                                    max={DEFAULT_CONFIG.defaultMidiRange[1]}
                                    label="reverb"
                                    globalValues={activeData.global && activeData.global.reverblevel !== undefined ? activeData.global.reverblevel : undefined}
                                    layerValues={reverblevel}
                                  />
                                </div>

                                <div className="col-4 col-md-3">
                                  <SingleThumbRangeSlider
                                    setValues={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'choruslevel', self)
                                      }
                                    }
                                    onFinalChange={
                                      (e) => {
                                        updateSingleLayerData(e, i, 'choruslevel', self)
                                      }
                                    }
                                    onReset={(e) => {
                                      updateSingleLayerData(e, i, 'choruslevel', self)
                                    }}
                                    onSyncToGlobal={
                                      (e) => {
                                        deleteSingleLayerData(i, 'choruslevel', self)
                                      }
                                    }
                                    values={
                                      choruslevel !== undefined ? choruslevel : activeData.global && activeData.global.choruslevel !== undefined ? activeData.global.choruslevel : [0]
                                    }
                                    min={DEFAULT_CONFIG.defaultMidiRange[0]}
                                    max={DEFAULT_CONFIG.defaultMidiRange[1]}
                                    label="chorus"
                                    globalValues={activeData.global && activeData.global.choruslevel !== undefined ? activeData.global.choruslevel : undefined}
                                    layerValues={choruslevel}
                                  />
                                </div>

                              </div>
                            </div>

                            <div className="col-12 col-md-3 order-md-1">
                              <div className="d-flex flex-column flex-lg-row  justify-content-center align-items-center">
                                <SwitchButton
                                  value={holdpedal}
                                  setValue={(e) => { this.setSwitchValue("holdpedal", i, e) }}
                                  label={"Hold pedal"}
                                />
                                <SwitchButton
                                  value={modulation}
                                  setValue={(e) => { this.setSwitchValue("modulation", i, e) }}
                                  label={"Modulation"}
                                />
                              </div>
                              <div className="d-flex flex-column flex-lg-row  justify-content-center align-items-center">
                                <SwitchButton
                                  value={pitchbend}
                                  setValue={(e) => { this.setSwitchValue("pitchbend", i, e) }}
                                  label={"Pitchbend"}
                                />
                                <SwitchButton
                                  value={keyaftertouch ? keyaftertouch : false}
                                  setValue={(e) => { this.setSwitchValue("keyaftertouch", i, e) }}
                                  label={"Aftertouch"}
                                />
                              </div>
                            </div>


                          </div>

                        </div>

                        {isSongType && <div className={`select-layer-button ${channel === this.state.selectedChannel ? "is--active" : ""}`} onClick={() => this.setSelectedChannel(channel)} title={`Channel ${channel}: click to edit`}></div>}

                      </div>
                    )
                  }
                  )}

                </div>
                {/* Piano layer end */}

                {this.state.faq && this.state.faq.map((d, i) => (
                  <NoticeBox key={i} title={d.title} date={d.date} defaultExpanded={false}>
                    <div dangerouslySetInnerHTML={{ __html: d.body }}></div>
                  </NoticeBox>
                ))}

                

                <div className="row mt-3">
                  <div className="col-12 col-sm-6">
                    <NoticeBox title="Yamaha community support" defaultExpanded={false}>
                      <div>Please read article published on <a href="https://yamahamusicians.com/forum/viewtopic.php?f=53&t=18133&sid=7dfd6809826e109f872d35fd6998ad23" target="_blank">YamahaMusicians.com</a></div>
                    </NoticeBox>
                  </div>
                  <div className="col-12 col-sm-6">
                    <NoticeBox title="Casio community support" defaultExpanded={false}>
                      <div>Please read article published on <a href="https://www.casiomusicforums.com/index.php?/topic/20506-a-webmidi-master-keyboard-editor-midistage-w-px-s1000-custom-voices/" target="_blank">CasioMusicforums.com</a></div>
                    </NoticeBox>
                  </div>
                </div>

              </main>
              <footer>
                <CookieConsent />
                <p>© 2021 MidiStage All Rights Reserved. For personal use only.</p>
              </footer>
            </div>
          )}
        </>
      );


    } else {
      return (<div className="container-fluid dark-theme">No data</div>)
    }

  }
}

export default MidiStage;