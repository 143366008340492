//@flow

import React, { Component } from "react";
import "./UserPresetButtons.scss";
import { capitalise } from "./../../../utils/helpers";
import UploadMidiFile from "./upload-midi-button";
import type { Data } from "../../organism/MidiStage/MidiStage";

const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

//
// Types
// ----------------

type Props = {
  data: Array<Data>,
  selectedPreset: number,
  setPreviousPreset: Function,
  setSelectedPreset: Function,
  setData: Function,
  gm: Array
}

type State = {
  data: Array<Data>,
  selectedPreset: number,
  showDeleteConfirmation: boolean,
  showRenameInputBox: boolean,
  showNewPresetOptions: boolean,
  renameInputValue?: string
}

class UserPresetButtons extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      selectedPreset: this.props.selectedPreset,
      showDeleteConfirmation: false,
      showRenameInputBox: false,
      showNewPresetOptions: false,
      renameInputValue: ""
    };
  }


  componentDidMount() {
    this.setState({ selectedPreset: this.props.selectedPreset, data: this.props.data })
  }

  UNSAFE_componentWillUpdate(props) {
    if (this.state.selectedPreset !== props.selectedPreset || this.state.data !== props.data) {
      this.setState({ selectedPreset: props.selectedPreset, data: props.data })
    }
  }

  handleChange = (e) => {
    if (e.target.name === "user-presets-buttons") {
      const selectedPreset = +e.target.value;
      this.props.setPreviousPreset(this.props.selectedPreset);
      this.props.setSelectedPreset(selectedPreset);
      this.setState({ selectedPreset, showDeleteConfirmation: false, showRenameInputBox: false })

    }
    if (e.target.name === "rename-user-preset") {
      this.setState({ renameInputValue: e.target.value })
    }
  }

  handleMenuClicked = (e) => {
    const type = e.currentTarget.getAttribute('data-value');
    const { data, selectedPreset } = this.props;

    if (type === "rename") {
      this.setState({ showRenameInputBox: true })
    }
    if (type === "new") {
      this.setState({ showNewPresetOptions: true })
    }
    if (type === "duplicate") {

      const activeData = data[selectedPreset];
      let newData = [];
      
      data.forEach(d => { 
        let newObj = {...d};
        newData.push(newObj)
     });
      newData.push(activeData);

      this.props.setData(newData);
      setTimeout(()=>{
        this.props.setPreviousPreset(this.props.selectedPreset);
        this.props.setSelectedPreset(newData.length - 1);
      },0)

    }
    if (type === "delete") {
      this.setState({ showDeleteConfirmation: true });
    }
  }

  handleNewPreset = (e) => {
    const type = e.currentTarget.getAttribute('data-value');
    const { data, selectedPreset } = this.props;
    let newData = data;
    if (type === "live" || type === "style") {
      newData.push({ layers: [], type })
      this.props.setPreviousPreset(selectedPreset);
      this.props.setSelectedPreset(newData.length - 1);
      this.props.setData(newData);
      this.setState({ showNewPresetOptions: false });
    }
    if (type === "cancel") {
      this.setState({ showNewPresetOptions: false });
    }

  }

  handleDelete = (e) => {
    const type = e.currentTarget.getAttribute('data-value');
    const { data, selectedPreset } = this.props;
    let newData = data;
    if (type === "delete") {
      newData.splice(+selectedPreset, 1);
      let newSelectedPreset;
      if (newData.length > 1) {
        newSelectedPreset = selectedPreset - 1 < 0 ? 0 : selectedPreset - 1;
      } else {
        newSelectedPreset = 0;
      }
      this.props.setData(newData);
      setTimeout(()=>{
        if(newData.length > 1) {
          this.props.setPreviousPreset(selectedPreset);
        } else {
          this.props.setPreviousPreset(null);
        }
        this.props.setSelectedPreset(newSelectedPreset);
      },10)
    }
    this.setState({ showDeleteConfirmation: false });
  }


  handleRename = (e) => {
    const type = e.currentTarget.getAttribute('data-value');
    const { data, selectedPreset } = this.props;

    if (type === "rename") {
      const { renameInputValue } = this.state;
      if (renameInputValue !== null) {

        // rename
        let newData = [];
        let obj = {}
        for (let i = 0; i < data.length; i++) {
          if (i === selectedPreset) {
            obj = {
              ...data[i],
              name: renameInputValue
            }
          } else {
            obj = { ...data[i] }
          }
          newData.push(obj);
        }
        this.props.setData(newData);

      } else {

        let newData = [];
        let obj = {}
        for (let i = 0; i < data.length; i++) {
          if (i === selectedPreset) {
            obj = { ...data[i] }
            if (obj.name) delete obj.name; // delete name
          } else {
            obj = { ...data[i] }
          }
          newData.push(obj);
        }
        this.props.setData(newData);
      }
      this.setState({ showRenameInputBox: false, renameInputValue: "" });
    } else {
      this.setState({ showRenameInputBox: false, renameInputValue: "" });
    }

  }

  presetName = (i) => {
    const { data } = this.props;
    const { name, type } = data[i];
    const _type = type ? capitalise(type) : 'Live';
    return name !== undefined ? name : `${_type} ${alphabet[i]}`;
  }

  handleNewDataWithMidiFile = (newObj) => {
    const { data, selectedPreset } = this.props;
    let newData = data;
    newData.push(newObj);
    this.props.setPreviousPreset(selectedPreset);
    this.props.setSelectedPreset(newData.length - 1);
    this.props.setData(newData);
    this.setState({ showNewPresetOptions: false });
  }


  render() {
    const { data } = this.props;
    const { selectedPreset, showDeleteConfirmation, showRenameInputBox, showNewPresetOptions } = this.state;

    return (

      <>
        <form className="user-preset-buttons" id="user-preset-buttons"
          onChange={(e) => { this.handleChange(e) }} onSubmit={e => { e.preventDefault(); }}>
          <div className="d-flex align-items-center justify-content-center">
            <div className="user-preset-title d-flex align-items-center justify-content-center">User Presets</div>
            <div className="user-preset-menu d-flex justify-content-center">

              
              
              {!showDeleteConfirmation && !showRenameInputBox && !showNewPresetOptions && <div className="user-preset-menu__inner">
                <button className="button" data-value="new" onClick={this.handleMenuClicked}>New</button>
                <button className="button" data-value="duplicate" onClick={this.handleMenuClicked}>Duplicate</button>
                <button className="button" data-value="rename" onClick={this.handleMenuClicked}>Rename</button>
                {data.length > 1 && <button className="button" data-value="delete" onClick={this.handleMenuClicked}>Delete</button>}
              </div>}

              {showNewPresetOptions && <div className="user-preset-menu__inner">
                <p>Select mode?</p>
                <button className="button secondary" data-value="live" onClick={this.handleNewPreset}>Live</button>

                <UploadMidiFile 
                  setData={(e)=>{ this.handleNewDataWithMidiFile(e) }}
                  gm={this.props.gm}
                />

                {/* <button className="button warning" data-value="style" onClick={this.handleNewPreset}>Style</button> */}

                <button className="button" data-value="cancel" onClick={this.handleNewPreset}>Cancel</button>
              </div>}

              {showDeleteConfirmation && <div className="user-preset-menu__inner">
                <p>Are you sure?</p>
                <button className="button" data-value="delete" onClick={this.handleDelete}>Delete <strong>{this.presetName(selectedPreset)}</strong></button>
                <button className="button secondary" data-value="cancel" onClick={this.handleDelete}>Cancel</button>
              </div>}

              {showRenameInputBox && <div className="user-preset-menu__inner">
                <input name="rename-user-preset" type="text" placeholder={`Preset ${alphabet[selectedPreset]}`} maxLength="30" pattern="[a-zA-Z0-9\s]+" />
                <button className="button secondary" data-value="rename" onClick={this.handleRename}>Save</button>
                <button className="button" data-value="cancel" onClick={this.handleRename}>Cancel</button>
              </div>}

            </div>
          </div>
          <ul>
            {data && data.map((d, i) => {
              const isActive = i === selectedPreset;
              const labelClass = [
                ( d.type ? d.type : "live"),
                (this.presetName(i).length > 30 ? "text-small" : "")
              ].join(" ");
              return(
              <li key={i}>
                <input
                  type="radio"
                  name={"user-presets-buttons"}
                  id={`p${i}`}
                  value={i}
                  checked={isActive}
                  readOnly
                />
                <label htmlFor={`p${i}`} className={labelClass} >
                  <div className="top">
                    <div className="letter">{alphabet[i]}</div>
                    <div className={`led ${isActive ? "is--on" : ""}`}></div>
                  </div>
                  {this.presetName(i)}
                </label>
              </li>
            )}
            )}
          </ul>
        </form>
      </>
    );
  }
}

export default UserPresetButtons;
