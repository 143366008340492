//@flow

import React, { Component } from "react";
import "./Button.scss";


//
// Types
// ----------------

type Props = {
  setOff: Function,
  setOn: Function,
  text: Array,
  customStyle?: string,
  title?: string
}

type State = {
  isOn: boolean
}

class myButton extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOn: this.props.defaultValue
    };
  }

  handleClick=(e)=>{
    const { isOn } = this.state;
    this.setState({ isOn: !isOn })
    if(isOn) {
      this.props.setOff('off')
    } else {
      this.props.setOn('on')
    }
  }

  render() {
    const {
      text,
      customStyle,
      title
    } = this.props;
    const { isOn } = this.state;
  
    return (
      <button className={`mybutton ${customStyle} ${isOn ? "is--active": ""}`} onClick={this.handleClick} title={title ? title : undefined}>
        {isOn ? text[0] : text[1]}
      </button>
    );
  }
}

export default myButton;
