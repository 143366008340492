//@flow

import React, { Component } from "react";
import "./LayerToggleSwitches.scss";
import { convertVoiceToObject } from "./../../organism/MidiStage/helpers/webmidi-helpers";
import MidiEventsIndicator from "../../atom/MidiEventsIndicator/MidiEventsIndicator";
import { DEFAULT_CONFIG } from "./../../organism/MidiStage/constant";
import { dynamicSort } from "./../../../utils/helpers";
import type { Data } from "../../organism/MidiStage/MidiStage";

//
// Types
// ----------------

type Props = {
  midiEvent: any,
  data: Array<Data>, 
  setData: Function, 
  selectedPreset: number
}

type State = {
  loaded: boolean,
  data: Array<Data>,
  selectedPreset: number
}

class LayerToggleSwitches extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: this.props.data,
      selectedPreset: this.props.selectedPreset,
    };
  }


  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(props) {
    if(this.state.selectedPreset !== props.selectedPreset) {
      this.loadData(props);
    }
  }


  loadData = (newProps) => {
    let self = this;
    this.setState({ loaded: false });
    const processData = new Promise(function (resolve, reject) {
        resolve(newProps ? newProps : self.state)
    });

    Promise.all([processData]).then(function (results) {
      const newProps = results[0];
      self.setState({ 
        data: newProps.data, 
        selectedPreset: newProps.selectedPreset, 
        loaded: true
      });
    });
  }


  handleClick = (e) => {
    const channel = +e.currentTarget.getAttribute('data-channel');
    const { data, selectedPreset } = this.props;

    let newData = []; let obj = {};
    for (let i = 0; i < data.length; i++) {

      if (i === selectedPreset) {

        const layersData = data[i].layers;
        const channelsArr = layersData.map(d => d.channel);
        const index = channelsArr.indexOf(channel);

        let layerArr = []; let layerObj = {};
        for (let k = 0; k < layersData.length; k++) {
          if (k === index) {
            const value = layersData[k].isEnabled;
            layerObj = {
              ...layersData[k],
              isEnabled: !value
            }
            layerArr.push(layerObj)
          } else {
            layerObj = { ...layersData[k] }
            layerArr.push(layerObj)
          }
        }
        obj = { ...data[i], layers: layerArr }
        newData.push(obj);

      } else {
        obj = { ...data[i] };
        newData.push(obj);
      }

    }
    this.props.setData(newData);
  }

  initNewChannel = (e) => {
    const channel = +e.currentTarget.getAttribute('data-channel');
    const { data, selectedPreset } = this.props;

    let newData = [];
    for (let i = 0; i < data.length; i++) {
      if (i === selectedPreset) {

        let layersArr = [];
        const layersData = data[i].layers;
        for (let k = 0; k < layersData.length; k++) {
          layersArr.push({ ...layersData[k] })
        }
        let newObj = { ...DEFAULT_CONFIG.defaultLayer, channel }
        layersArr.push(newObj);
        layersArr.sort(dynamicSort("channel"));

        let newLayerObj = { 
          ...data[i],
          layers: layersArr
        }
        if(data[i].name !== undefined) {
          newLayerObj.name = data[i].name
        }
        newData.push(newLayerObj);

      } else {
        newData.push({ ...data[i] }); // clone
      }
    }
    this.props.setData(newData);
  }

  renderSwitches = () => {
    const { data, selectedPreset, midiEvent } = this.props;
    
    const _selectedPreset = selectedPreset > data.length - 1 ? selectedPreset - 1 : selectedPreset; 
    const activeData = data[_selectedPreset];
    const channelsData = activeData.layers.map(d => d.channel);
    const {
      channelTotals,
      drumChannels
    } = DEFAULT_CONFIG;

    let listItems = []
    for (let i = 0; i < channelTotals; i++) {
      const channel = i + 1;
      if (drumChannels.indexOf(channel) < 0) {
        // default no data
        let item = <li key={i}>
          <button data-channel={channel} onClick={this.initNewChannel} className="is--init">
            <div className="channel is--empty"><span>{channel}</span></div>
          </button>
        </li>;

        if (channelsData.indexOf(channel) >= 0) {
          // channel data is exist
          // find active status of this channel, return class is--active if so.
          const indexOfThisData = channelsData.indexOf(channel);
          const layerData = activeData && activeData.layers[indexOfThisData];
          const { isEnabled, voice } = layerData;

          let voiceName = "-- no voice --";
          let isMidiInputForChannel = false;

          if (midiEvent !== null && isEnabled) isMidiInputForChannel = midiEvent.indexOf(channel) >= 0 ? true : false;

          if (voice) {
            const voiceObj = convertVoiceToObject(voice);
            voiceName = voiceObj.n;
          }

          item = <li key={i}>
            <button data-channel={channel} onClick={this.handleClick} className={isEnabled ? "is--active" : ""} >
              <div className="channel">
                <span>{channel}</span>
                <MidiEventsIndicator input={isMidiInputForChannel} />
              </div>
              <div className="label">{voiceName}</div>
            </button>
          </li>
        }
        listItems.push(item);
      }
    }
    if(!this.state.loaded) return null;
    return listItems;
  }

  render() {
    return (
      <ul className="layer-toogle-switches">
        {this.renderSwitches()}
      </ul>

    );
  }
}

export default LayerToggleSwitches;
