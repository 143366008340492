//@flow
import React, { Component } from "react";
import SingleThumbRangeSlider from "../../atom/TwoThumbsRangeSlider/SingleThumbRangeSlider";
import { convertRange } from "../../../utils/helpers";
import { getCombinedGlobalAndLayerValue, sendPlayNoteFromMidiPlayer, sendControlChangeFromMidiPlayer, midiToPitch } from "../../organism/MidiStage/helpers/webmidi-helpers";
import WebMidi from "webmidi";
import * as Tone from 'tone';
import { Midi } from "@tonejs/midi";
import "./MidiPlayer.scss";
import { DEFAULT_CONFIG } from "../MidiStage/constant";
import type { Data }  from "../MidiStage/MidiStage";

export const timeFormat = (SECONDS) => new Date(SECONDS * 1000).toISOString().substr(14, 9)

let timer;

//
// Types
// ----------------

type Props = {
  data?: Array<Data>,
  selectedPreset: number,
  devicesOutputsActive?: number,
  isDevelopment: boolean,
  setMidiEvent?: Function
}

type State = {
  loaded: boolean,
  toneEnabled: boolean,
  isMuted: boolean,
  data: Array<Data>,
  selectedPreset: number,
  time: number,
}



class Player extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.synths = []
    this.eventIds = []
    this.midi = []

    this.state = {
      loaded: false,
      data: this.props.data,
      toneEnabled: false,
      isMuted: true,
      selectedPreset: this.props.selectedPreset,
      time: 0
    };

  }

  componentDidMount() {
    this.initiateSong();
  }


  UNSAFE_componentWillUpdate(props) {
    if (this.state.selectedPreset !== props.selectedPreset) {
      this.setState({
        data: props.data,
        selectedPreset: props.selectedPreset,
        time: 0
      }, () => {
        this.initiateSong(props.data);
        this.handleStop();
      })
    }
  }

  setMidiEvent = (e) => {
    this.props.setMidiEvent(e);
  }


  resetMidiPlayer = () => {
    
    // removing unused synths
    if(this.synths.length > 0) {
      this.synths.forEach(synth => {
        try {
          if(!synth.disposed) synth.dispose();
        } catch(err) {
          console.log(err)
        }
      });
      this.synths = [];
    }
    
    // clear Timeline
    if(this.eventIds.length > 0) {
      this.eventIds.forEach(eventId => {
        Tone.Transport.clear(eventId);
      })
      this.eventIds = [];
    }

  }


  initiateSong = (newData) => {

    this.resetMidiPlayer();

    const data = newData ? newData : this.props.data;
    const { midiData } = data;

    if (midiData !== undefined) {

      let self = this;
      const processMidiData = new Promise(function (resolve, reject) {

        let parsedMidi = new Midi(midiData);

        try {

          parsedMidi.tracks.forEach(track => {

            if (track.notes.length > 0) {
              
              //tracks have notes and controlChanges
              const synth = new Tone.PolySynth(Tone.Synth, {
                envelope: {
                  attack: 0.02,
                  decay: 0.1,
                  sustain: 0.3,
                  release: 1,
                },
              }).toDestination();

              self.synths.push(synth);

              const channel = track.channel + 1;

              // schedule all of the events
              track.notes.forEach(note => {

                const eventId = Tone.Transport.schedule((time) => {

                  if(!self.state.isMuted) synth.triggerAttackRelease(
                    note.name,
                    note.duration,
                    time,
                    note.velocity
                  );

                  sendPlayNoteFromMidiPlayer(note, channel);

                  // if (isDevelopment) console.log("note", note.midi, "channel", channel, "velocity", note.velocity)

                }, note.time);

                self.eventIds.push(eventId)

              }); // notes

              // control changes
              const trackControlChangesArr = Object.keys(track.controlChanges);
              if (trackControlChangesArr.length > 0) {
                trackControlChangesArr.forEach(cc => {

                  const { value, ticks } = track.controlChanges[cc][0];

                  const eventId = Tone.Transport.schedule(() => {

                    sendControlChangeFromMidiPlayer(cc, value, channel);

                    // if (isDevelopment) console.log(ticks, "cc", typeof cc,cc, "channel", channel, "value", value)

                  }, Tone.Time(ticks + "i").toSeconds());

                  self.eventIds.push(eventId)

                });
              }

            }
          }); // track

          resolve(parsedMidi)

        } catch (error) {

          console.log(error)

        }

      });

      Promise.all([processMidiData]).then(function (results) {
        if (self.state.isDevelopment) console.log("this.midi", results[0])
        self.midi = results[0];
        self.setState({ loaded: true });
      });

    } // data

  }




  silenceAllActiveNotes = () => {
    if(this.synths.length > 0) this.synths.forEach(synth => {
      synth.releaseAll();
    });
  }

  handleSynthToggle = (e) => {
    const { isMuted } = this.state;
    this.setState({ isMuted: !isMuted });
  }

  handlePlay = async (e) => {
    const initStart = () => {
      Tone.Transport.start();
      timer = setInterval(() => {
        const time = Tone.Transport.getSecondsAtTime();
        this.setState({ time });
        if (time > this.midi.duration) {
          this.handleStop();
        }
      }, 100);

      this.setState({ isPlaying: true })
    }

    // init
    if (!this.state.toneEnabled) {
      await Tone.start().then(
        () => {
          initStart();
          this.setState({ toneEnabled: true });
        }
      );
    } else {
      initStart();
    }
  }

  handlePause = (e) => {
    this.silenceAllActiveNotes();
    Tone.Transport.pause();
    clearInterval(timer);
    this.setState({ isPlaying: false })
  }

  handleStop = (e) => {
    this.silenceAllActiveNotes();
    Tone.Transport.stop();
    Tone.Transport.ticks = 0;
    clearInterval(timer);
    this.setState({ isPlaying: false, time: 0 })
  }

  setTime = (e) => {
    if (e[0] !== 0) {
      const ticks = Tone.Transport.toTicks(e[0]);
      Tone.Transport.ticks = ticks;
      const time = Tone.Transport.getSecondsAtTime();
      this.setState({ time })
    } else {
      this.handleStop();
    }
  }


  render() {
    if (!this.state.loaded) return null;
    const { isPlaying, time, isMuted } = this.state;
    const midiData = this.midi;

    const timeline = time === 0 ? timeFormat(midiData.duration) : timeFormat(time);
    const mutedClass = isMuted ? "" : "is--active";
    const svgXlinkHref = isMuted ? "#icon-unmute" : "#icon-mute";

    return (
      <div className="midi-player">
        {midiData.name && <h3>{midiData.name}</h3>}
        <div className="row">
          <div className="col control-buttons">

            <div className="panel panel-left">

              <button onClick={this.handleSynthToggle} title="Test sound on/off" className={mutedClass}>
                <svg
                  viewBox="0 0 35 35" version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref={svgXlinkHref} />
                </svg>
              </button>

            </div>


            <SingleThumbRangeSlider
              setValues={(e) => { }}
              onReset={(e) => {
                this.handleStop()
              }}
              onFinalChange={
                (e) => {
                  this.setTime(e)
                }
              }
              step={1}
              values={[time]}
              min={0}
              max={midiData.duration}
              label={timeline}
              isTimeFormat
            />

            <div className="panel panel-right">

              {!isPlaying && <button onClick={this.handlePlay} title="Play">
                <svg
                  viewBox="0 0 35 35" version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="#icon-play" />
                </svg>
              </button>}
              {isPlaying && <button onClick={this.handlePause} title="Pause" className="pause">
                <svg
                  viewBox="0 0 35 35" version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="#icon-pause" />
                </svg>
              </button>}

            </div>

          </div>
        </div>


      </div>
    );
  }
}

export default Player;
