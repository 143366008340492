// @flow
import generateChannelArr from "./generate-channel-arr";
import { sendProgramChangeOfVoice, sendControlChange, sendPitchBendRangeMessage, sendMasterTuningMessage } from "./webmidi-helpers";
import { DEFAULT_CONFIG } from "../constant";

export default function initialiseNewData(self) {

  const { ready, data, previousPreset, selectedPreset, devicesOutputsActive } = self.state;

  self.setState({ channelArr: generateChannelArr(data, selectedPreset) });

  const _selectedPreset = selectedPreset > data.length - 1 ? selectedPreset - 1 : selectedPreset; 
  const activeData = data[_selectedPreset];

  // set global tuning data if exist
  const globalData = activeData.global;
  const globalTuningValue = globalData && globalData.tuning ? globalData.tuning[0] : 0;

  if (ready) {


    // check previous data

    /*
    Helper
    */
    const isSameWithPrevVoice = (channel, voice) => {
      let res = false;
      if(previousPreset !== null) {
        const prevData = data[previousPreset];
        if(prevData !== undefined) {
          const prevLayersData = data[previousPreset].layers;
          if(prevLayersData !== undefined) {
            prevLayersData.forEach(prev => {
              if(prev.channel === channel) {
                res = prev.voice === voice
              }
            })
          }
        }
      }
      return res
    }

    for (let i = 0; i < data[selectedPreset].layers.length; i++) {
      const { voice, channel, expressioncourse, tuning, pitchbendrange, pancoarse, reverblevel, tremololevel, choruslevel } = data[selectedPreset].layers[i];

      if (voice) {

        const _isSameWithPrevVoice = isSameWithPrevVoice(channel, voice);

        // voice
        if(_isSameWithPrevVoice !== undefined && !_isSameWithPrevVoice) {
          // send PC if voice is different]
          sendProgramChangeOfVoice(voice, channel, devicesOutputsActive, self.setMidiEvent)
        }

        // expression
        if (expressioncourse && expressioncourse.length > 0) {
          const expressionValue = expressioncourse && expressioncourse.length > 0 ? expressioncourse[0] : DEFAULT_CONFIG.defaultExpression;
          sendControlChange(expressionValue, channel, "expressioncoarse", devicesOutputsActive, self.setMidiEvent);
        }

        // pitchbendrange
        if (pitchbendrange) {
          const pitchbendrangeValue = typeof pitchbendrange === 'object' ? pitchbendrange[0] : 0;
          if (pitchbendrangeValue !== 0) {
            sendPitchBendRangeMessage(pitchbendrangeValue, devicesOutputsActive, self.setMidiEvent, [channel])
          }
        }

        // tuning
        if (tuning) {
          const tuningValue = typeof tuning === 'object' ? globalTuningValue + tuning[0] : 0;
          if (tuningValue !== 0) {
            sendMasterTuningMessage(tuningValue, devicesOutputsActive, self.setMidiEvent, [channel])
          }
        }

        // pan
        if (pancoarse) {
          sendControlChange(pancoarse, channel, "pancoarse", devicesOutputsActive, self.setMidiEvent);
        }

        // reverb
        if (reverblevel) {
          sendControlChange(reverblevel, channel, "reverblevel", devicesOutputsActive, self.setMidiEvent);
        }

        // tremolo
        if (tremololevel) {
          sendControlChange(tremololevel, channel, "tremololevel", devicesOutputsActive, self.setMidiEvent);
        }

        // chorus
        if (choruslevel) {
          sendControlChange(choruslevel, channel, "choruslevel", devicesOutputsActive, self.setMidiEvent);
        }

      } // end voice


    }
  }
}