// @flow
import { sendControlChange, sendPitchBendRangeMessage, sendMasterTuningMessage, sendSysexMessage } from "./webmidi-helpers";

export function updateSingleGlobalData(values, type, self) {
  const { data, selectedPreset, devicesOutputsActive, channelArr, isDevelopment } = self.state;

  let newGlobalData = {
    ...data[selectedPreset].global,
    [`${type}`]: values
  };
  let newData = data;
  newData[selectedPreset].global = newGlobalData;
  self.setState({ data: newData });

  if (type === "pancoarse") {
    if (isDevelopment) console.log(values[0], channelArr.panArr, type)
    sendControlChange(values[0], channelArr.panArr, type, devicesOutputsActive, self.setMidiEvent);
  }

  if (type === "reverblevel") {
    if (isDevelopment) console.log(values[0], channelArr.reverbArr, type)
    sendControlChange(values[0], channelArr.reverbArr, type, devicesOutputsActive, self.setMidiEvent);
  }

  if (type === "tremololevel") {
    if (isDevelopment) console.log(values[0], channelArr.tremoloArr, type)
    sendControlChange(values[0], channelArr.tremoloArr, type, devicesOutputsActive, self.setMidiEvent);
  }

  if (type === "choruslevel") {
    if (isDevelopment) console.log(values[0], channelArr.chorusArr, type)
    sendControlChange(values[0], channelArr.chorusArr, type, devicesOutputsActive, self.setMidiEvent);
  }

  if (type === "pitchbendrange") {
    if (isDevelopment) console.log(values[0], channelArr.pitchbendRangeArr, type)
    sendPitchBendRangeMessage(values[0], devicesOutputsActive, self.setMidiEvent, channelArr.pitchbendRangeArr);
  }

  if (type === "tuning") {
    if (isDevelopment) console.log(values[0], channelArr.isEnabledWithVoiceArr, type)
    sendMasterTuningMessage(values[0], devicesOutputsActive, self.setMidiEvent, channelArr.isEnabledWithVoiceArr);
  }

  if (type.includes("sysex")) {
    const { sysexControlArr } = self.state;
    const selectedSysexGroup = sysexControlArr.filter(d => d.value === type)[0];
    const selectedSysexObj = selectedSysexGroup.options.filter(o => o.name === values)[0];
    sendSysexMessage(selectedSysexObj, devicesOutputsActive, self.setMidiEvent, channelArr.isEnabledWithVoiceArr);
  }
}

export function deleteSingleGlobalData(type, self) {
  const { data, selectedPreset, channelArr, devicesOutputsActive, isDevelopment } = self.state;
  let newGlobalData = {
    ...data[selectedPreset].global
  };
  delete newGlobalData[`${type}`];

  let newData = data;
  if (Object.keys(newGlobalData).length > 0) {
    newData[selectedPreset].global = newGlobalData;
  } else {
    delete newData[selectedPreset].global;
  }

  self.setState({ data: newData });

  // trigger MIDI message
  const values = [0];
  if (type === "pancoarse") {
    if (isDevelopment) console.log(64, channelArr.panArr, type)
    sendControlChange(64, channelArr.panArr, type, devicesOutputsActive, self.setMidiEvent);
  }
  if (type === "reverblevel") {
    if (isDevelopment) console.log(values[0], channelArr.reverbArr, type)
    sendControlChange(values[0], channelArr.reverbArr, type, devicesOutputsActive, self.setMidiEvent);
  }
  if (type === "tremololevel") {
    if (isDevelopment) console.log(values[0], channelArr.tremoloArr, type)
    sendControlChange(values[0], channelArr.tremoloArr, type, devicesOutputsActive, self.setMidiEvent);
  }
  if (type === "choruslevel") {
    if (isDevelopment) console.log(values[0], channelArr.chorusArr, type)
    sendControlChange(values[0], channelArr.chorusArr, type, devicesOutputsActive, self.setMidiEvent);
  }
  if (type === "pitchbendrange") {
    if (isDevelopment) console.log(2, channelArr.pitchbendRangeArr, type)
    sendPitchBendRangeMessage(2, devicesOutputsActive, self.setMidiEvent, channelArr.pitchbendRangeArr);
  }
  if (type === "tuning") {
    if (isDevelopment) console.log(values[0], channelArr.isEnabledWithVoiceArr, type)
    sendMasterTuningMessage(values[0], devicesOutputsActive, self.setMidiEvent, channelArr.isEnabledWithVoiceArr);
  }

  if (type.includes("sysex")) {
    const { sysexControlArr } = self.state;
    const selectedSysexObj = sysexControlArr.filter(d => d.value === type)[0].options[0];
    sendSysexMessage(selectedSysexObj, devicesOutputsActive, self.setMidiEvent, channelArr.isEnabledWithVoiceArr);
  }

}