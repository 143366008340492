//@flow

import React, { Component } from "react";
import { isIOS, encryptData, saveDataToServer } from "../../../utils/helpers";
import "./DownloadConfigFile.scss";

const _isIOS = isIOS();

//
// Types
// ----------------

type Props = {
  data: Array
}

type State = {
  displayInputbox: boolean,
  inputValue?: string
}

// https://stackoverflow.com/questions/55613438/reactwrite-to-json-file-or-export-download-no-server

class DownloadConfigFile extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      displayInputbox: false,
      inputValue: null
    };
  }

  encryptPartialData = (appData) => {

    const { data } = appData;
    
    let newData = data;
    const { midiData } = data;
    if(midiData) {
      newData.midiData = JSON.stringify(midiData)
    }

    let newObj = {
      ...appData,
      data: encryptData(newData)
    }

    return newObj
  }

  handleDisplay = (e) => {
    e.preventDefault();
    this.setState({displayInputbox : true, inputValue: this.generateFilename() })
  }

  setFilename = (e) => {
    this.setState({inputValue: e.target.value});
  }

  download(url, filename) {
    const a = document.createElement('a');
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    if(document.body) document.body.removeChild(a)
    this.handCompleted();
  }

  generateFilename = () => {
    /*
    let d = new Date();
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let hh = new Intl.DateTimeFormat('en', { hour: 'numeric',hour12: false }).format(d);
    let mm = new Intl.DateTimeFormat('en', { minute: 'numeric',hour12: false }).format(d);
    */
    return `midistage-${Date.now()}`;
  }

  handCompleted = (e) => {
    this.setState({ inputValue: null, displayInputbox : false });

    if(process.env.NODE_ENV === 'development') {
      console.log(this.props.data)
    } else {
      // send data to server here for research purposes
      saveDataToServer(this.props.data, "tmp", this.state.inputValue);
    }

  }

  handleCancel = (e) => {
    e.preventDefault();
    this.setState({ inputValue: null, displayInputbox : false });
  }

  render() {

    const { displayInputbox, inputValue } = this.state;
    const filename = inputValue ? inputValue.split(".")[0] + ".json" : `midistage-${Date.now()}.json`;
    const urlData = `data:application/octet-stream;charset=utf-8,
    ${encodeURIComponent(
      JSON.stringify(
        this.encryptPartialData(this.props.data)
      )
    )}`
    
    return (
      <div className="download-config-file">
        {!displayInputbox && <button className="mybutton" onClick={this.handleDisplay}>Save</button>}
        {displayInputbox && <div className="">
          <form onChange={this.setFilename} onSubmit={(e) => {e.preventDefault();}} id="download-config-file">
            <a className="mybutton"
              onClick={this.handleCancel}
            >
              Cancel
            </a>
            <div className="inputbox">
            <input name="filename" type="text" defaultValue={this.state.inputValue} placeholder="Enter a filename" maxLength="40" />
              <a className="mybutton primary"
                href={urlData}
                download={filename}
                onClick={this.handCompleted}
              >
                OK
              </a>
              {_isIOS && <div className="warning" onClick={this.handleCancel}><strong>Saving</strong> a file is not working in <u>iOS</u> due to WebKit limitation. <strong>Loading</strong> a file is fine. Use desktop when creating presets.</div>}
            </div>
          </form>
          
        </div>}
      </div>
    );
  }
}

export default DownloadConfigFile;